import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StaticPageService from "../../services/staticPageService";
import RcImage from "../../attributes/RcImage";
import RichText from "../RichText";
import SocialPlatforms from "../../partials/SocialPlatforms/SocialPlatforms";
import ScrollToTop from "../../utilities/ScrollToTop";

export const StaticPage = () => {
  let { slug } = useParams();
  const navigate = useNavigate();

  const [staticPage, setStaticPage] = useState([]);

  useEffect(() => {
    if (slug) {
      let staticPageService = new StaticPageService();
      staticPageService.getStaticPageBySlug(slug).then((response) => {
        const findedData = response.data.data?.find(
          (x) => x.attributes.Slug === slug
        );

        if (findedData) {
          setStaticPage(findedData);
        } else {
          navigate("/about-us");
        }
      });
    }
  }, [slug]);

  return (
    <div className="blog-detail-main">
      <div className="main-content">
        <div className="left-content">
          <div className="blog-image">
            {staticPage?.attributes?.Main_Image?.data && (
              <RcImage
                src={staticPage?.attributes?.Main_Image?.data?.attributes?.url}
              ></RcImage>
            )}
          </div>
          <div className="blog-title">
            <p>{staticPage?.attributes?.Title}</p>
          </div>
          <div className="blog-paragraphs">
            {Array.isArray(staticPage?.attributes?.Static_Page_Paragraphs) &&
              staticPage?.attributes?.Static_Page_Paragraphs.map(
                (blogParagraph, index) => (
                  <div key={index} className="blog-paragraph">
                    <p className="blog-paragraph-sub-heading">
                      {blogParagraph.Subheading}
                    </p>
                    <p
                      style={{ whiteSpace: "pre-line" }}
                      className="blog-paragraph-description"
                    >
                      <RichText data={blogParagraph.Description}></RichText>
                    </p>
                    {blogParagraph?.Media?.data && (
                      <RcImage
                        className="blog-paragraph-image"
                        src={blogParagraph?.Media?.data?.attributes?.url}
                      ></RcImage>
                    )}
                  </div>
                )
              )}
          </div>
        </div>
        <div className="right-content">
          <div className="search-container">
            <RcImage
              src={require("../../icons/search-black-icon.png")}
            ></RcImage>
            <input type="search" placeholder="Search..."></input>
          </div>
          <div className="social">
            <p className="blog-left-content-common-title">Get Social</p>
            <SocialPlatforms></SocialPlatforms>
          </div>
        </div>
      </div>
      <ScrollToTop></ScrollToTop>
    </div>
  );
};
