import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "../styles/contactUs.css";
import GoogleCaptcha from "../partials/GoogleCaptcha";
import ContactUserService from "../services/contactUsService";
import { Spinner } from "@chakra-ui/react";
import toast from "react-hot-toast";

export default function ContactUs(props) {
  const { register, handleSubmit, formState, reset } = useForm();

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    if (captchaVerified) {
      setLoading(true);
      let contactUsService = new ContactUserService();
      await contactUsService
        .postContactUsForm(data)
        .then(() => {
          setLoading(false);
          toast.success("Success.", {
            position: "top-right",
          });
          reset();
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  function checkCaptchaIsVerified(state) {
    setCaptchaVerified(state);
  }

  return (
    <div className="contactUsComponentMain">
      <p className="title">{props.title}</p>
      {props.description ? (
        <p className="description">{props.description}</p>
      ) : (
        false
      )}
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="firstNameAndLastName">
          <div className="formGroup">
            <p className="inputTitle">FIRST NAME</p>
            <input
              type="text"
              {...register("firstName", { required: true })}
            ></input>
          </div>
          <div className="formGroup">
            <p className="inputTitle">LAST NAME</p>
            <input
              type="text"
              {...register("lastName", { required: true })}
            ></input>
          </div>
        </div>
        <div className="formGroup">
          <p className="inputTitle">ORGANISATION</p>
          <input
            type="text"
            {...register("organisation", { required: true })}
          ></input>
        </div>
        <div className="formGroup">
          <p className="inputTitle">EMAIL</p>
          <input
            type="email"
            {...register("email", { required: true })}
          ></input>
        </div>
        <div className="formGroup">
          <p className="inputTitle">PROFESSIONAL TITLE</p>
          <input
            type="text"
            {...register("professionalTitle", { required: true })}
          ></input>
        </div>
        <div className="formGroup">
          <p className="inputTitle">COUNTRY</p>
          <input
            type="text"
            {...register("country", { required: true })}
          ></input>
        </div>
        <div className="formGroup">
          <p className="inputTitle">NOTE</p>
          <input type="text" {...register("note", { required: true })}></input>
        </div>
        <div className="formGroup">
          <GoogleCaptcha
            checkCaptchaIsVerified={checkCaptchaIsVerified}
          ></GoogleCaptcha>
        </div>

        <button
          type="submit"
          disabled={loading || !formState.isValid}
          className="btnSend"
        >
          Send {loading && <Spinner size="sm" />}
        </button>
      </form>
    </div>
  );
}
