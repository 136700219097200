import axios from "axios";
import Constants from "../constants/constants";

export default class EventsService{


    getEvents(){
        let populate = [
            "Event_Type",
            "Show_on_the_navigation_bar",
            "Event_ID.Event_Main_Image",
            "Event_Schedule.Hour_and_Activity",
            "Keynote_Speakers.Connect_on_LinkedIn",
            "Keynote_Speakers.SpeakerImage",
            "Sponsor_Info.Sponsor_Logo",
        ]
        return axios.get(`${Constants.apiServerPath}add-events/?populate=${populate}&sort=Event_ID.Event_Date:desc`)
    }

    getEventById(id){
        let populate = [
            "Event_Type",
            "Show_on_the_navigation_bar",
            "Event_ID.Event_Main_Image",
            "Event_Schedule.Hour_and_Activity",
            "Keynote_Speakers.Connect_on_LinkedIn",
            "Keynote_Speakers.SpeakerImage",
            "Keynote_Speakers.Keynote_Speaker_Video.Video_Media",
            "Sponsor_Info.Sponsor_Logo",
        ]
        return axios.get(`${Constants.apiServerPath}add-events/${id}/?populate=${populate}`)
    }

    getEventBySlug(slug){
        let populate = [
            "Event_Type",
            "Show_on_the_navigation_bar",
            "Event_ID.Event_Main_Image",
            "Event_Schedule.Hour_and_Activity",
            "Keynote_Speakers.Connect_on_LinkedIn",
            "Keynote_Speakers.SpeakerImage",
            "Keynote_Speakers.Keynote_Speaker_Video.Video_Media",
            "Sponsor_Info.Sponsor_Logo",
        ]
        return axios.get(`${Constants.apiServerPath}add-events/?filters[url][$eq]=${slug}&populate=${populate}`)
    }

    getStEvent(){
        let populate = [
            "Events_Page_Main_Title",
            "Events_Page_Main_Title_2",
            "Events_Main_Image",
            "Upcoming_Events_Description",
            "Past_Events_Description",
            "Events_Archive.Button"
          ]
        return axios.get(`${Constants.apiServerPath}st-event/?populate=${populate}`)
    }
}