import React from 'react'
import ReactMarkDown from 'react-markdown';
import rehypeRaw from "rehype-raw";

export default function RichText(data) {
  return (
    <>
        <ReactMarkDown className='rich-text-content' children={data.data} rehypePlugins={[rehypeRaw]} />
    </>
  )
}
