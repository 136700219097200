import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/events.css";
import EventsCard from "../EventsCard";
import RichText from '../RichText';
import ScrollToTop from '../../utilities/ScrollToTop';
import EventsService from "../../services/eventsService";

function Events() {

  let currentDate = new Date();

  const [events, setEvents] = useState([])
  const [stEvent, setStEvent] = useState([])
  const [eventYear, setEventYear] = useState(currentDate.getFullYear().toString());
  const [sortedActiveEventData, setSortedEctiveEventData] = useState([]);
  useEffect(() => {
      let eventsService = new EventsService();
      eventsService.getEvents().then(result => setEvents(result.data.data))
      eventsService.getStEvent().then(response => setStEvent(response.data.data));
  }, [])

  useEffect(() => {
  
  const today = new Date();
  let UpcomingEventList=getUpcomingEvents(events);
  const sorted = [...UpcomingEventList].sort((a, b) => {
    const diffA = dateDifference(a.attributes.Event_ID.Event_Date, today);
    const diffB = dateDifference(b.attributes.Event_ID.Event_Date, today);
    return diffA - diffB;
  });

  setSortedEctiveEventData(sorted);
}, [events])

  const navigate = useNavigate();

  const navigateToRoute = (route) => {
    navigate(route);
  };

  function getYears(){
    let years = [];
    let date = new Date();
    let year = date.getFullYear();

    for(let i = 1; i <= 5; i++){
      if(year !== 2020 && year !== 2021){
        years.push(year.toString());
      }
      else{
        i--;
      }
      year -= 1;
    }
    return years;
  }


  function getUpcomingEvents(events){
    let currentDate = new Date(); 
    let data = [];
    events.forEach((element) => {
      let dateParam = new Date(element?.attributes?.Event_ID?.Event_End_Date);
      if (currentDate <= dateParam) {
        data.push(element);
      }
    });
    return data;
  }

  function getPastEvents(events, eventYear){
    let currentDate = new Date(); 
    let data = [];
    events.forEach((element) => {
      let dateParam = new Date(element?.attributes?.Event_ID?.Event_End_Date);
      if (currentDate > dateParam && dateParam.getFullYear().toString() === eventYear) {
        data.push(element);
      }
    });
    return data;
  }
  const dateDifference = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return Math.abs(d1 - d2); // Mutlak farkı alıyoruz (gün cinsinden)
  };
  function getCurrentEventYear(year){
    if(eventYear === year){
      return "year active"
    }
    return "year";
  }

  const scrollToTop = (top) => {
    window.scrollTo({
      top: top,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      <div className="eventsHomePage" style={{backgroundImage: `url(${stEvent?.attributes?.Events_Main_Image?.data?.attributes?.url})`}}>
        <div className="ellipseBlue"></div>
        <div
          style={{
            padding: "0px 800px 0px 80px",
            gap: "40px",
            height: "261px",
          }}
          className="joinAltoTextComponent"
        >
          <div className="joinAltoTextComponent">
            <h1 style={{ color: "white" }} className="joinAltoHeader1">{stEvent?.attributes?.Events_Page_Main_Title}</h1>
            <h1 style={{ color: "white", textAlign: "left", margin: "10px 0px 20px 0px" }} className="joinAltoHeader2"><RichText data={stEvent?.attributes?.Events_Page_Main_Title_2}></RichText></h1>
              <button
              onClick={() => scrollToTop(750)}
              className="blueButton explore-button"
              style={{
                backgroundColor: "white",
                color: "#00529C",
                width: "280px",

              }}
            >
              Explore
            </button>
          </div>
        </div>
      </div>

      <div className="upcomingEvents">
        <h1 className="eventsHeader">{stEvent?.attributes?.Upcoming_Events_Description?.Title}</h1>
        <p className="eventsText" style={{whiteSpace: "pre-line", marginRight: "15px", marginLeft: "15px"}}><RichText data={stEvent?.attributes?.Upcoming_Events_Description?.Description}></RichText></p>
        <div className="eventsFrame">
          {sortedActiveEventData.map((file) => (
            <EventsCard key={file.id} file={file}></EventsCard>
          ))}
        </div>
      </div>
      
      <div className="pastEventsContainer">
        <h1 className="eventsHeader">{stEvent?.attributes?.Past_Events_Description?.Title}</h1>
        <p className="eventsText" style={{whiteSpace: "pre-line"}}><RichText data={stEvent?.attributes?.Past_Events_Description?.Description}></RichText></p>
        <div className="chips">
          {getYears().map((year, index) => (
            <div onClick={() => setEventYear(year)} key={index} className={getCurrentEventYear(year)}>
              <p className="year-text"> {year} </p>
            </div>
          ))}
        </div>
        <div className="eventPageEvent">
          {getPastEvents(events, eventYear).map((file) => (
            <EventsCard key={file.id} file={file}></EventsCard>
          ))}
          {getPastEvents(events, eventYear).length <= 0 ? (
            <p style={{fontSize: "20px"}}>Event not found</p>
          ) : false }
        </div>
      </div>
      
      <div className="evntsArchive">
        <h1 className="eventsHeader">{stEvent?.attributes?.Events_Archive?.Title}</h1>
        <p className="eventsText" style={{whiteSpace: "pre-line"}}><RichText data={stEvent?.attributes?.Events_Archive?.Description}></RichText></p>
          {stEvent?.attributes?.Events_Archive?.Button ? ( 
          <Link to={stEvent?.attributes?.Events_Archive?.Button?.ButtonLink} target="_blank"><button
            className="default-button"
            style={{
              width: "280px",
              textAlign: "center"
            }}
          >
            {stEvent?.attributes?.Events_Archive?.Button?.ButtonText}
        </button></Link> ) : false}
      </div>
      <div
        className="section"
      >
        <div className="sectionText">
          <h1
            style={{ color: "white", textAlign: "left", marginLeft: "30px"}}
            className="eventsHeader"
          >
            Want to make sure <br></br> you don’t miss the next event?
          </h1>
        </div>
        <button onClick={() => navigateToRoute("/contact")} className="default-button btn-want-to-make-see-upcoming-event" style={{marginRight: "50px"}}> Contact Us </button>
      </div>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}
export default Events;