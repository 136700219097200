import React from "react";
import "../styles/global.css";
import "../styles/altoFrameCard.css";
import { useNavigate } from "react-router-dom";

function AltoMemoryCard(props) {

  function getDay(date){
    let convertDate = new Date(date);
    return convertDate.getDate();
  }

  function getMonthName(date){
    let monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ];
  
    let convertDate = new Date(date);
    return monthNames[convertDate.getMonth()];
  }

  function getEventDate(startDate, endDate){
    let startDateConvert = new Date(startDate);
    let endDateConvert = new Date(endDate);

    if(startDate === endDate){
        return (`${getDay(endDate)} ${getMonthName(endDate)}`)
    }
    else if(startDateConvert.getMonth() === endDateConvert.getMonth()){
        return (`${getDay(startDate)} - ${getDay(endDate)} ${getMonthName(endDate)}`)
    }
    else if(startDateConvert.getMonth() !== endDateConvert.getMonth()){
        return (`${getDay(startDate)} ${getMonthName(startDate)} - ${getDay(endDate)} ${getMonthName(endDate)}`)
    }
    return "Date is not calculated";
  }

  const navigate = useNavigate();

  const navigateToRoute = (path) => {
    navigate(path);
  };

  return (
    <div className="altoMemoryCard">
      <div
        style={{
          overflow: "hidden",
        }}
      >
        <img
          style={{
            width: "100%",
            minHeight: "320px",
            maxHeight: "320px",
            borderRadius: "15px",
          }}
          src={props?.altoMemo?.attributes?.Event_ID?.Event_Main_Image?.data?.attributes?.url}
          alt="cardImg"
        ></img>
      </div>
      <div className="cardContent"  onClick={() => navigateToRoute(`/events/detail/${props?.altoMemo?.attributes?.Event_ID?.id}`)} style={{cursor: "pointer"}}>
        <p className="dateText">{getEventDate(props?.altoMemo?.attributes?.Event_ID?.Event_Date, props?.altoMemo?.attributes?.Event_ID?.Event_End_Date)}</p>
        <p className="title">{props?.altoMemo?.attributes?.Event_ID?.Event_Name}</p>
        <div className="description" style={{ overflow: "hidden" }}>
          <p>{props?.altoMemo?.attributes?.Event_ID?.Event_Description}</p>
        </div>

        <div className="viewDetails" style={{marginTop: "20px"}}>
          <span>View Details</span>
        </div>
        
      </div>
    </div>
  );
}

export default AltoMemoryCard;
