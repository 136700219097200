import React, { useEffect, useState } from 'react'
import "../../styles/joinNow.css";
import ScrollToTop from '../../utilities/ScrollToTop';
import JoinAltoBecomeAMemberService from '../../services/joinAltoBecomeAMemberService';
import RcImage from '../../attributes/RcImage'
import RcLink from '../../attributes/RcLink'

export default function JoinNow() {

  const [stJoinAlto, setStJoinAlto] = useState([]);

  useEffect(() => {
    let joinAltoBecomeAMemberService = new JoinAltoBecomeAMemberService();
    joinAltoBecomeAMemberService.getStJoinAltoBecomeAMember().then((response) => {
      setStJoinAlto(response.data.data);
      console.log(response.data.data);
    })
  }, [])

  return (
    <div className='joinNowMain'>
      <div className='joinNowMainInner'>
        <div
        className="joinAltoDiv joinNowMainBanner"
        style={{
          width: "100%",
          height: "640px",
          backgroundImage: `url(${stJoinAlto?.attributes?.Header_Section?.Become_Member_Main_Image?.data?.attributes?.url})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundClip: "content-box",
        }}
      >
        <div className="joinAltoDivEllipseBlue"></div>
        <div
          style={{
            padding: "0px 800px 0px 80px",
            gap: "40px",
          }}
          className="joinAltoTextComponent"
        >
          <div style={{width: "100%"}} className="joinAltoTextComponent">
            <h1 style={{ color: "white", width: "100%"}} className="joinAltoHeader1">
                {stJoinAlto?.attributes?.Header_Section.Main_Title}
            </h1>
            <h1
              style={{ color: "white", textAlign: "left" }}
              className="joinAltoHeader2"
            >
              {stJoinAlto?.attributes?.Header_Section.Main_Title_2}
            </h1>
          </div>
        </div>
      </div>

      <div className='download-the-registration-form'>
          <p>{stJoinAlto?.attributes?.Download_Forms.Title}</p>
          <p>{stJoinAlto?.attributes?.Download_Forms.Description}</p>
      </div>

      <div className='items'>
        {Array.isArray(stJoinAlto?.attributes?.Download_Forms?.Membership_Type) && stJoinAlto?.attributes?.Download_Forms?.Membership_Type.map((element, index) => (
          <div className='item'>
            <div className='image'>
              <RcImage src={element?.Image?.data?.attributes?.url}></RcImage>
            </div>
            {element.Membership_Type && element?.Insert_Documents.data ?
              (
                <div className='link'>
                  <RcLink href={element?.Insert_Documents?.data?.attributes?.url} text={element.Membership_Type}></RcLink>
                </div>
              ) : 
              (
                <p style={{fontSize: "20px"}}> There are no files to download</p>
              )
            }
          </div>
        ))}
      </div>
     </div>
     <ScrollToTop></ScrollToTop>
    </div>
  )
}
