import React, { useEffect, useState } from "react";
import "../../styles/eventsDetail.css";
import { Link, useParams } from "react-router-dom";
import EventsService from "../../services/eventsService";
import ScrollToTop from "../../utilities/ScrollToTop";
import Navigation from "../Navigation";
import RichText from "../RichText";
import ButtonMailto from "../ButtonMailto";
import RcImage from "../../attributes/RcImage";
import RcLink from "../../attributes/RcLink";

export default function EventsDetail() {
  let { slug } = useParams();

  const [event, setEvent] = useState({});

  useEffect(() => {
    let eventsService = new EventsService();
    eventsService
      .getEventBySlug(slug)
      .then((result) => setEvent(result.data.data[0]));
  }, [slug]);

  function getDay(date) {
    let convertDate = new Date(date);
    return convertDate.getDate();
  }

  function getMonthName(date) {
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let convertDate = new Date(date);
    return monthNames[convertDate.getMonth()];
  }

  function getEventDate(startDate, endDate) {
    let startDateConvert = new Date(startDate);
    let endDateConvert = new Date(endDate);

    if (startDate === endDate) {
      return `${getDay(endDate)} ${getMonthName(endDate)}`;
    } else if (startDateConvert.getMonth() === endDateConvert.getMonth()) {
      return `${getDay(startDate)} - ${getDay(endDate)} ${getMonthName(
        endDate
      )}`;
    } else if (startDateConvert.getMonth() !== endDateConvert.getMonth()) {
      return `${getDay(startDate)} ${getMonthName(startDate)} - ${getDay(
        endDate
      )} ${getMonthName(endDate)}`;
    }
    return "Date is not calculated";
  }

  function eventDateIsPassed(date) {
    let currentDate = new Date();
    let dateParam = new Date(date);
    if (currentDate > dateParam) {
      return true;
    }
    return false;
  }

  function prettyDate2(time) {
    if (time != null) {
      let tempTime = "";
      tempTime = time;
      return tempTime.substring(0, 5);
    }
    return null;
  }

  return (
    <div className="event-detail-main">
      <div className="event-detail-top-content">
        <div className="left-content">
          <div className="event-title">
            <p>{event?.attributes?.Event_ID?.Event_Name}</p>
          </div>
          <div className="event-detail">
            <div className="event-detail-item">
              <div className="event-detail-key">
                <p>Date</p>
              </div>
              <div className="event-detail-value">
                <p>
                  {getEventDate(
                    event?.attributes?.Event_ID?.Event_Date,
                    event?.attributes?.Event_ID?.Event_End_Date
                  )}
                </p>
              </div>
            </div>
            <div className="event-detail-item">
              <div className="event-detail-key">
                <p>Event Categories</p>
              </div>
              <div className="event-detail-value">
                <p>{event?.attributes?.Event_ID?.Event_Categories}</p>
              </div>
            </div>
            <div className="event-detail-item">
              <div className="event-detail-key">
                <p>Organizer</p>
              </div>
              <div className="event-detail-value">
                <p>{event?.attributes?.Event_ID?.Event_Organizer}</p>
              </div>
            </div>
            <div className="event-detail-item">
              <div className="event-detail-key">
                <p>Phone</p>
              </div>
              <div className="event-detail-value">
                <p>{event?.attributes?.Event_ID?.Event_Organizer_Phone}</p>
              </div>
            </div>
            <div className="event-detail-item">
              <div className="event-detail-key">
                <p>Mail</p>
              </div>
              <div className="event-detail-value">
                <p>
                  <ButtonMailto
                    label={event?.attributes?.Event_ID?.Event_Organizer_Mail}
                    mailto={event?.attributes?.Event_ID?.Event_Organizer_Mail}
                  ></ButtonMailto>
                </p>
              </div>
            </div>
          </div>
          {!eventDateIsPassed(event?.attributes?.Event_ID?.Event_End_Date) ? (
            <div style={{ marginTop: "30px" }}>
              <Link to={event?.attributes?.register_form_url} target="_blank">
                <button className="default-button btn-event-register">
                  Register
                </button>
              </Link>
            </div>
          ) : (
            false
          )}
        </div>
        <div className="right-content">
          <div className="description">
            <p>
              <RichText
                data={event?.attributes?.Event_ID?.Event_Description}
              ></RichText>
            </p>
          </div>
        </div>
      </div>
      <div className="event-image">
        <RcImage
          src={
            event?.attributes?.Event_ID?.Event_Main_Image?.data?.attributes?.url
          }
        ></RcImage>
      </div>

      {event?.attributes?.Event_Schedule?.length > 0 ? (
        <div className="event-schedules-main">
          <p className="event-schedules-title">Event schedule</p>
          <div className="event-schedules">
            {Array.isArray(event?.attributes?.Event_Schedule) &&
              event?.attributes?.Event_Schedule.map((eventSchedule, index) => (
                <div className="event-schedule">
                  <div className="event-schedule-day-date">
                    <p className="event-schedule-day">
                      {eventSchedule?.Day_Name}
                    </p>
                    <p className="event-schedule-date">
                      {eventSchedule?.Event_Date}
                    </p>
                  </div>
                  <div className="event-schedule-activities">
                    {Array.isArray(eventSchedule?.Hour_and_Activity) &&
                      eventSchedule?.Hour_and_Activity.map(
                        (eventScheduleActivity, index) => (
                          <div className="event-schedule-activitiy">
                            <p className="event-schedule-activitiy-hour">
                              {prettyDate2(eventScheduleActivity?.Time)}
                            </p>
                            <p className="event-schedule-activitiy-name">
                              {eventScheduleActivity?.Event}
                            </p>
                          </div>
                        )
                      )}
                  </div>
                </div>
              ))}
          </div>
          <div
            className="event-schedules-descriptions"
            style={{ marginTop: "40px", marginBottom: "40px" }}
          >
            <p>
              <RichText
                data={event?.attributes?.Event_Schedule_Bottom_Description}
              ></RichText>
            </p>
          </div>
          {!eventDateIsPassed(event?.attributes?.Event_ID?.Event_End_Date) && (
            <Link to={event?.attributes?.register_form_url} target="_blank">
              <button className="default-button">Register</button>
            </Link>
          )}
        </div>
      ) : (
        false
      )}

      {event?.attributes?.Keynote_Speakers.length > 0 ? (
        <div className="event-speakers">
          <p className="event-speakers-main-title">
            More Information About our Keynote Speakers and Sessions
          </p>
          <div className="speakers">
            {Array.isArray(event?.attributes?.Keynote_Speakers) &&
              event?.attributes?.Keynote_Speakers?.map((element, index) => (
                <div key={index} className="speaker">
                  {element?.SpeakerImage?.data ? (
                    <div className="speaker-left-container">
                      <div className="speaker-image">
                        <RcImage
                          src={element?.SpeakerImage?.data?.attributes?.url}
                        ></RcImage>
                      </div>
                      <p className="speaker-name">{element?.Name}</p>
                      <RcLink
                        href={element?.Connect_on_LinkedIn?.LinkedIn_URL}
                        className="speaker-linkedin"
                        text={element?.Connect_on_LinkedIn?.Connect_on_LinkedIn}
                      ></RcLink>
                    </div>
                  ) : (
                    false
                  )}
                  <div className="speaker-right-container">
                    <p className="speaker-content-title">{element?.Name}</p>
                    <p className="speaker-content-description">
                      <RichText data={element.Description}></RichText>
                    </p>
                    {element?.Keynote_Speaker_Video ? (
                      <div className="video-container">
                        <p className="speaker-content-title">
                          {element?.Keynote_Speaker_Video.Video_Title}
                        </p>
                        <p className="speaker-content-description">
                          <RichText
                            data={
                              element?.Keynote_Speaker_Video?.Video_Description
                            }
                          ></RichText>
                        </p>
                        {element?.Keynote_Speaker_Video?.Video_Media?.data
                          ?.attributes?.url ? (
                          <video
                            width="100%"
                            height="auto"
                            controls
                            style={{ marginTop: "30px" }}
                            controlsList="nodownload"
                          >
                            <source
                              src={
                                element?.Keynote_Speaker_Video?.Video_Media
                                  ?.data?.attributes?.url
                              }
                              type="video/mp4"
                            ></source>
                          </video>
                        ) : (
                          false
                        )}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        false
      )}

      {event?.attributes?.Sponsor_Info ? (
        <div className="event-sponsor-info">
          <p className="event-sponsor-info-text">
            {event?.attributes?.Sponsor_Info?.Sponsor_Text}
          </p>
          <RcImage
            className="event-sponsor-info-logo"
            src={
              event?.attributes?.Sponsor_Info?.Sponsor_Logo?.data?.attributes
                ?.url
            }
          ></RcImage>
        </div>
      ) : (
        false
      )}

      <div style={{ marginTop: "100px" }}>
        <Navigation></Navigation>
      </div>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}
