import axios from "axios";
import Constants from "../constants/constants";

export default class NewsAndProjectService {
  getNewsAndProjects() {
    return axios.get(
      `${Constants.apiServerPath}news-and-projects/?populate=*&sort=Sorting_Number:desc`
    );
  }
  getNewsAndProjectById(id) {
    return axios.get(
      `${Constants.apiServerPath}news-and-projects/${id}/?populate=*`
    );
  }
  getNewsAndProjectBySlug(slug) {
    return axios.get(
      `${Constants.apiServerPath}news-and-projects/?filters[url][$eq]=${slug}&populate=*`
    );
  }

  getStNewsAndProjects() {
    return axios.get(
      `${Constants.apiServerPath}st-newsand-project/?populate=*`
    );
  }
}
