import axios from "axios";
import Constants from "../constants/constants";

export default class JoinAltoService{

    getJoinAlto(){
        let populate = [
            "Header_Section_Join_Alto.Main_Button",
            "Header_Section_Join_Alto.Main_Image",
            "Join_Alto_Description.Benefits",
            "Special_Offers_for_Members.Special_Offers",
            "How_to_Join_Alto.Join_Steps",
            "Membership_Categories.Category_Type",
            "BankInformations",
            "Membership_Criterias.Button",
            "Membership_Criterias.Image",
        ]
        return axios.get(`${Constants.apiServerPath}st-join-alto/?populate=${populate}`)
    }
}