import axios from "axios";
import Constants from "../constants/constants";

export default class StaticPageService {
  getStaticPageBySlug(slug) {
    let populate = [
      "Title",
      "Slug",
      "Seo_Description",
      "Seo_Title",
      "Static_Page_Paragraphs",
      "Static_Page_Paragraphs.Media",
      "Main_Image",
    ];
    return axios.get(
      `${Constants.apiServerPath}static-pages/?filters[slug][$eq]=${slug}&populate=${populate}`
    );
  }
}
