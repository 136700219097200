import axios from "axios";
import Constants from "../constants/constants";

export default class JoinAltoBecomeAMemberService{

    getStJoinAltoBecomeAMember(){
        let populate = [
            "Header_Section.Become_Member_Main_Image",
            "Download_Forms.Membership_Type.Insert_Documents",
            "Download_Forms.Membership_Type.Image"
        ]
        return axios.get(`${Constants.apiServerPath}st-join-alto-become-a-member/?populate=${populate}`)
    }
}