import React from 'react'
import "../../styles/members.css"
import { useState, useEffect } from "react";
import ScrollToTop from '../../utilities/ScrollToTop';
import MemebersService from '../../services/membersService';
import { Link } from 'react-router-dom';
import RichText from '../RichText'
import ButtonMailto from '../ButtonMailto';

export default function Members() {


  const [members, setMembers] = useState([])

  useEffect(() => {
      let eventsService = new MemebersService();
      eventsService.getMembers().then(result => setMembers(result.data.data))
  }, [])

  function getMembersByType(memeberType){
    let data = [];
    let membersData = members?.attributes?.Organize_Members;
    if(Array.isArray(membersData)){
        membersData.forEach((element) => {
            if(element.Membership_Type === memeberType){
                data.push(element);
            }
        })
    }
    return data;
  }
    
  const tabs = [{id: 1, name: "Full Members"}, {id: 2, name: "Associate Non-Trading Members"}, {id: 3, name: "Associate Trading Members"}];

  const [currentTab, setCurrentTab] = useState([])  

  useEffect(() => {
    setCurrentTab(tabs[0]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getCurrentTabClass(id){
    if(currentTab.id === id){
        return 'tab-button active-tab-button'
    }
    return 'tab-button'
  }


  return (
    <div className='membersMain'>
      <div className='banner joinAltoDiv' style={{backgroundImage: `url(${members?.attributes?.Member_Main_Image?.data?.attributes?.url})`}}>
        <div
            style={{
                padding: "0px 800px 0px 80px",
                gap: "40px",
                height: "261px",
            }}
            className="newsContainer"
            >
            <div className='bannerDescriptionInner'>
                <h1 style={{ color: "white" }} className="newsHeader">
                {members?.attributes?.Main_Title}
                </h1>
                <h1 style={{ color: "white", textAlign: "left"}} className="joinAltoHeader2">{members?.attributes?.Members_Description}</h1>
            </div>
            </div>
        <div className="joinAltoDivEllipseBlue"></div>
      </div>

      <div className='welcome-to-our-members'>
            <div>
                <p><RichText data={members?.attributes?.Subheading_Title}></RichText></p>
            </div>
            <RichText data={members?.attributes?.Subheading_Description}></RichText>
      </div>

      <div className='member-list'>
        <div className='members-tab-buttons'>
            <p onClick={() => setCurrentTab(tabs[0])} className={getCurrentTabClass(1)}>Full Members</p>
            <p onClick={() => setCurrentTab(tabs[1])} className={getCurrentTabClass(2)}>Associate Non-Trading Members</p>
            <p onClick={() => setCurrentTab(tabs[2])} className={getCurrentTabClass(3)}>Associate Trading Members</p>
        </div>
        <div className='members-tabs'>
            {currentTab.id === 1 ? (
                <div className='full-members-tab'>
                    <div className='table'>
                        <div className='table-title'>
                            <p>{currentTab.name}</p>
                        </div>
                        <div className='table-content'>
                            <div className='columns'>
                                <p className='m-r-auto w-45 text-left'>Name</p>
                                <p className='m-l-r-auto w-25 text-left'>Web Address</p>
                                <p className='m-l-auto w-30 text-right'>Email</p>
                            </div>
                            <div className='rows'>
                                {getMembersByType(currentTab.name).map((row, index) => (
                                    <div key={index} className='display-flex row'>
                                        <p className='m-r-auto w-45 text-left'>{row.Member_Name}</p>
                                        <p className='m-l-r-auto w-25 text-left' style={{color: '#00529C'}}><Link to={`https://${row.Member_Web_Adress}`} target='_blank'>{row.Member_Web_Adress}</Link></p>
                                        <p className='m-l-auto w-30 text-right'><ButtonMailto label={row.Member_Mail_Adress} mailto={row.Member_Mail_Adress}></ButtonMailto></p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : false}
            {currentTab.id === 2 ? (
                <div className='associate-non-trading-members-tab'>
                        <div className='table'>
                        <div className='table-title'>
                            <p>{currentTab.name}</p>
                        </div>
                        <div className='table-content'>
                            <div className='columns'>
                                <p className='m-r-auto w-45 text-left'>Name</p>
                                <p className='m-l-r-auto w-25 text-left'>Web Address</p>
                                <p className='m-l-auto w-30 text-right'>Email</p>
                            </div>
                            <div className='rows'>
                                {getMembersByType(currentTab.name).map((row, index) => (
                                    <div key={index} className='display-flex row'>
                                        <p className='m-r-auto w-45 text-left'>{row.Member_Name}</p>
                                        <p className='m-l-r-auto w-25 text-left' style={{color: '#00529C'}}><Link to={`https://${row.Member_Web_Adress}`} target='_blank'>{row.Member_Web_Adress}</Link></p>
                                        <p className='m-l-auto w-30 text-right'><ButtonMailto label={row.Member_Mail_Adress} mailto={row.Member_Mail_Adress}></ButtonMailto></p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : false}
            {currentTab.id === 3 ? (
                <div className='associate-non-trading-members-tab'>
                    <div className='table'>
                        <div className='table-title'>
                            <p>{currentTab.name}</p>
                        </div>
                        <div className='table-content'>
                            <div className='columns'>
                                <p className='m-r-auto w-45 text-left'>Name</p>
                                <p className='m-l-r-auto w-25 text-left'>Web Address</p>
                                <p className='m-l-auto w-30 text-right'>Email</p>
                            </div>
                            <div className='rows'>
                                {getMembersByType(currentTab.name).map((row, index) => (
                                    <div key={index} className='display-flex row'>
                                        <p className='m-r-auto w-45 text-left'>{row.Member_Name}</p>
                                        <p className='m-l-r-auto w-25 text-left' style={{color: '#00529C'}}><Link to={`https://${row.Member_Web_Adress}`} target='_blank'>{row.Member_Web_Adress}</Link></p>
                                        <p className='m-l-auto w-30 text-right'><ButtonMailto label={row.Member_Mail_Adress} mailto={row.Member_Mail_Adress}></ButtonMailto></p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : false}
        </div>
      </div>
      <ScrollToTop></ScrollToTop>
    </div>
  )
}
