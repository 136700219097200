import { useField } from 'formik'
import {FormField} from "semantic-ui-react"
import React from 'react'

export default function HtTextInput({...props}) {
  
  const [field, meta] = useField(props)

  return (
    <div>
      <FormField error={meta.touched && !!meta.error}>
        <input {...field} {...props}></input>
        {meta.touched && !!meta.error ? (
            <p style={{color: "red", fontSize: "13px", marginTop: "2px"}}>*{field.name.toUpperCase()} cannot be left blank</p>
        ): null}
       </FormField>
    </div>
  )
}
