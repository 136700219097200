import React from 'react'

export default function RcLink({href, text, target='_blank', className}) {

  return (
    <>
        <a target={target} rel='noopener noreferrer' href={href} className={className}>{text}</a>
    </>
  )
}
