import React, { useEffect, useState } from "react";
import "../../styles/blogDetail.css";
import BlogService from "../../services/blogService";
import ScrollToTop from "../../utilities/ScrollToTop";
import { Link, useNavigate, useParams } from "react-router-dom";
import RichText from "../RichText";
import SocialPlatforms from "../../partials/SocialPlatforms/SocialPlatforms";
import LeftArrowIcon from "../../icons/left-arrow-black-icon.svg";
import RcImage from "../../attributes/RcImage";

export default function BlogDetail() {
  const navigate = useNavigate();

  const navigateToRoute = (path) => {
    navigate(path);
  };

  let { slug } = useParams();

  const [blogs, setBlogs] = useState([]);
  const [blogsForCategory, setBlogsForCategory] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);

  const [blog, setBlog] = useState({});

  useEffect(() => {
    if (slug) {
      let blogService = new BlogService();
      blogService
        .getBlogBySlug(slug)
        .then((response) => setBlog(response.data.data[0]));
    }
  }, [slug]);

  useEffect(() => {
    let blogService = new BlogService();
    blogService.getBlogs().then((result) => {
      setBlogs(result.data.data);
      setBlogsForCategory(result.data.data);
    });
  }, []);

  useEffect(() => {
    let blogCategories = [
      { id: 1, name: "All" },
      { id: 2, name: "Updates" },
      { id: 3, name: "Events" },
      { id: 4, name: "Information" },
      { id: 5, name: "Featured" },
      { id: 6, name: "Uncategorized" },
    ];
    setBlogCategories(blogCategories);
  }, []);

  function getBlogsByCategory(categoryName) {
    if (categoryName === "All") {
      setBlogsForCategory(blogs);
    } else {
      let blogsData = blogs.filter(
        (b) => b.attributes.Blog_Category === categoryName
      );
      setBlogsForCategory(blogsData);
    }
  }

  function getCategoryCount(categoryName) {
    if (categoryName === "All") {
      return blogs.length;
    }
    return blogs.filter((b) => b.attributes.Blog_Category === categoryName)
      .length;
  }

  return (
    <div className="blog-detail-main">
      <RcImage
        onClick={() => navigateToRoute("/blog")}
        className="return-main-blog-page-arrow"
        src={LeftArrowIcon}
      ></RcImage>
      <div className="main-content">
        <div className="left-content">
          <div className="blog-image">
            {blog?.attributes?.Blog_Main_Image?.data && (
              <RcImage
                src={blog?.attributes?.Blog_Main_Image?.data?.attributes?.url}
              ></RcImage>
            )}
          </div>
          <div className="blog-title">
            <p>{blog?.attributes?.Blog_Title}</p>
          </div>
          <div className="blog-paragraphs">
            {Array.isArray(blog?.attributes?.Blog_Paragraphs) &&
              blog?.attributes?.Blog_Paragraphs.map((blogParagraph, index) => (
                <div key={index} className="blog-paragraph">
                  <p className="blog-paragraph-sub-heading">
                    {blogParagraph.Subheading}
                  </p>
                  <p
                    style={{ whiteSpace: "pre-line" }}
                    className="blog-paragraph-description"
                  >
                    <RichText data={blogParagraph.Description}></RichText>
                  </p>
                  {blogParagraph?.Media?.data && (
                    <RcImage
                      className="blog-paragraph-image"
                      src={blogParagraph?.Media?.data?.attributes?.url}
                    ></RcImage>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className="right-content">
          <div className="search-container">
            <RcImage
              src={require("../../icons/search-black-icon.png")}
            ></RcImage>
            <input type="search" placeholder="Search..."></input>
          </div>
          <div className="social">
            <p className="blog-left-content-common-title">Get Social</p>
            <SocialPlatforms></SocialPlatforms>
          </div>
          <div className="categories-container">
            <p className="blog-left-content-common-title">Categories</p>
            <div className="categories">
              {blogCategories.map((category) => (
                <div
                  key={category.id}
                  onClick={() => getBlogsByCategory(category.name)}
                  className="category"
                >
                  <span>
                    {category.name} ({getCategoryCount(category.name)})
                  </span>
                  <RcImage
                    src={require("../../icons/right-arrow-blue-icon.png")}
                  ></RcImage>
                </div>
              ))}
            </div>
          </div>
          <div className="recent-posts-container">
            <p className="blog-left-content-common-title">Recent Posts</p>
            {blogsForCategory.map((blog, index) => (
              <div className="recent-blogs">
                <Link to={`/blog/detail/${blog.id}`}>
                  <p className="blog-title">{blog.attributes.Blog_Title}</p>
                </Link>
                <p className="date-and-comment">
                  <span className="blog-date">
                    {blog?.attributes?.Blog_Date}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}
