import React, { useState, useEffect } from "react";
import "../../styles/newsandprojects.css";
import NewsCard from "../NewsCard";
import ScrollToTop from '../../utilities/ScrollToTop';
import NewsAndProjectService from "../../services/newsAndProjectService";
import { Link } from "react-router-dom";
function NewsandProjects(props) {

  const [newsAndProjects, setNewsAndProjects] = useState([])
  const [newsAndProject, setNewsAndProject] = useState([])

  useEffect(() => {
      let newsAndProejctsService = new NewsAndProjectService();
      newsAndProejctsService.getNewsAndProjects().then(result => setNewsAndProjects(result.data.data))
      newsAndProejctsService.getStNewsAndProjects().then(response => setNewsAndProject(response.data.data));
  }, [])

  return (
    <div className="news-and-projects-main">
      <div
        style={{
          width: "100%",
          height: "640px",
          backgroundImage: `url(${newsAndProject?.attributes?.News_Header_Image?.data?.attributes?.url})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundClip: "content-box"
        }}
        className="joinAltoDiv"
      >
        <div className="joinAltoDivEllipseBlue"></div>
        <div
          style={{
            padding: "0px 800px 0px 80px",
            gap: "40px"
          }}
          className="newsContainer"
        >
          <div className="newsContainer">
            <h1 style={{ color: "white" }} className="newsHeader">{newsAndProject?.attributes?.Main_Title}</h1>
            <h1 style={{ color: "white", textAlign: "left" }} className="joinAltoHeader2">{newsAndProject?.attributes?.Main_Description}</h1>
          </div>
          {newsAndProject?.attributes?.Main_Button_NP ? (
            <Link to={newsAndProject?.attributes?.Main_Button_NP?.Link} target="_blank">
              <button className="default-button" style={{ backgroundColor: "white", color: "#00529C" }}>{newsAndProject?.attributes?.Main_Button_NP?.Button_Name}</button>
            </Link> ) : false
          }
        </div>
      </div>
      <div className="newsAndProjectnewsCardComponent">
        {newsAndProjects.filter(item => item.attributes?.Add_to_main_page).map((file) => (
          <NewsCard key={file.id} file={file} />
        ))}
      </div>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}

export default NewsandProjects;
