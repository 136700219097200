import React, { useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import "../styles/navbar.scss";
import "../styles/header.css";
import EventService from "../services/eventsService";
import NewsAndProjectService from "../services/newsAndProjectService";
import RcImage from "../attributes/RcImage";

export const MAX_CONTAINER_WIDTH = ["1300px"];

function Header() {
  const navigate = useNavigate();

  const navigateToRoute = (path) => {
    navigate(path);
  };

  const [eventMenuItems, setEventMenuItems] = useState([]);
  const [newsAndProjectsMenuItems, setNewsAndProjectsMenuItems] = useState([]);

  let linksData = [
    { id: 1, text: "About", link: "/about-us" },
    {
      id: 2,
      text: "Events",
      link: "/events",
      menuItems: true,
      menuItemsIsShowing: false,
    },
    {
      id: 3,
      text: "News & Projects",
      link: "/projects",
      menuItems: true,
      menuItemsIsShowing: false,
    },
    { id: 4, text: "Members", link: "/members" },
    { id: 5, text: "Join ALTO", link: "/member-benefits" },
    {
      id: 6,
      text: "Members Area",
      link: "https://alto.glueup.com/account/login",
    },
    { id: 7, text: "Contact", link: "/contact" },
  ];

  useEffect(() => {
    let eventService = new EventService();
    eventService.getEvents().then((response) => {
      let items = [];
      response.data.data.forEach((element) => {
        let item = {
          id: element.id,
          route: `/events/detail/${element?.attributes?.url}`,
          name: element.attributes?.Event_ID?.Event_Name,
          ShowNavigation: element?.attributes?.Show_on_the_navigation_bar,
        };
        items.push(item);
      });
      setEventMenuItems(items);
    });
  }, []);

  useEffect(() => {
    let newsAndProjectService = new NewsAndProjectService();
    newsAndProjectService.getNewsAndProjects().then((response) => {
      let items = [];
      response.data.data.forEach((element) => {
        let item = {
          id: element.id,
          route: `/projects/detail/${element.attributes?.url}`,
          name: element.attributes?.Main_Title_1,
          ShowNavigation: element?.attributes?.Show_on_the_navigation,
        };
        items.push(item);
      });
      setNewsAndProjectsMenuItems(items);
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [links, setLinks] = useState([]);
  useEffect(() => {
    setLinks(linksData);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchBarIshowing, setSearchBarIshowing] = useState(false);

  useEffect(() => {
    setSearchBarIshowing(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, false);

  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 1055 && menuOpen) {
      setMenuOpen(false);
      setSearchBarIshowing(false);
    }
    if (size.width <= 1055 && menuOpen) {
      setSearchBarIshowing(true);
    }
    if (size.width <= 1055 && searchBarIshowing) {
      setSearchBarIshowing(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  const closeMenu = () => {
    if (menuOpen) {
      setMenuOpen((p) => !p);
    }
  };

  function toggleMenuItemIsShowing(item) {
    setLinks((prevState) =>
      prevState.map((emp, i) =>
        emp.id === item.id
          ? { ...emp, menuItemsIsShowing: !emp.menuItemsIsShowing }
          : emp
      )
    );
  }

  function subMenuRoute(mainMenu, subMenu) {
    navigateToRoute(subMenu.route);
    if (menuOpen) {
      toggleMenuItemIsShowing(mainMenu);
      closeMenu();
    }
  }

  return (
    <header className="header">
      <div className="header__content">
        <Link to="/" className="header__content__logo">
          <RcImage src={require("../images/alto-logo.png")}></RcImage>
        </Link>
        {searchBarIshowing ? (
          <div className="search-bar">
            <input type="search" placeholder="Search"></input>
          </div>
        ) : (
          false
        )}
        <nav
          className={`${"header__content__nav"} 
          ${menuOpen && size.width < 1055 ? `${"isMenu"}` : ""} 
          }`}
        >
          <ul className="mainList">
            {links.slice(0, 4).map((link) => (
              <li className="mainItem">
                <div className="mainItemLink">
                  <Link
                    className="link-text"
                    onClick={closeMenu}
                    to={link.link}
                  >
                    {link.text}
                  </Link>
                  {link.menuItems ? (
                    <span
                      onClick={() => toggleMenuItemIsShowing(link)}
                      className={
                        link.menuItemsIsShowing
                          ? "rotateIconDown rightIcon"
                          : "rightIcon"
                      }
                    >
                      <RcImage
                        src={require("../icons/right-arrow-icon.png")}
                      ></RcImage>
                    </span>
                  ) : (
                    false
                  )}
                </div>
                {link.id === 2 ? (
                  <ul
                    style={{
                      display: link?.menuItemsIsShowing ? "block" : "none",
                    }}
                    className="subList"
                  >
                    {eventMenuItems.map((subItem) => {
                      if (subItem.ShowNavigation)
                        return (
                          <li
                            onClick={() => subMenuRoute(link, subItem)}
                            className="subItem"
                          >
                            {subItem.name}
                          </li>
                        );
                    })}
                  </ul>
                ) : (
                  false
                )}
                {link.id === 3 ? (
                  <ul
                    style={{
                      display: link?.menuItemsIsShowing ? "block" : "none",
                    }}
                    className="subList"
                  >
                    {newsAndProjectsMenuItems.map((subItem) => {
                      if (subItem.ShowNavigation)
                        return (
                          <li
                            onClick={() => subMenuRoute(link, subItem)}
                            className="subItem"
                          >
                            {subItem.name}
                          </li>
                        );
                    })}
                  </ul>
                ) : (
                  false
                )}
              </li>
            ))}
          </ul>
          <ul className="mainList mainListRight">
            {links.slice(4, links.length).map((link) => (
              <li className="mainItem">
                <div className="mainItemLink">
                  <Link onClick={closeMenu} to={link.link}>
                    {link.text}
                  </Link>
                </div>
              </li>
            ))}
            <li className="search-and-close-icon" style={{ cursor: "pointer" }}>
              {!searchBarIshowing ? (
                <RcImage
                  onClick={() => setSearchBarIshowing(true)}
                  src={require("../icons/search-icon.png")}
                ></RcImage>
              ) : (
                <RcImage
                  onClick={() => setSearchBarIshowing(false)}
                  src={require("../icons/close-icon.png")}
                ></RcImage>
              )}
            </li>
          </ul>
        </nav>
        <div className="header__content__toggle">
          {!menuOpen ? (
            <AiOutlineMenu onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose
              onClick={menuToggleHandler}
              className="close-icon"
            />
          )}
        </div>
      </div>
    </header>
  );
}
export default Header;
