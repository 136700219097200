import axios from "axios";
import CryptoJS from 'crypto-js';

export class GlueUpAPIService {
  static API_version = '1.0';
  static API_endpoint = 'https://api-services.glueup.com/v2/';
  static API_tenant = 860;
  static API_orgID = 5840;
  static API_account = 'alto';
  static API_privatekey = 'MF4CAQACEADmWSMd1qitGzV8KL00OmUCAwEAAQIQAIGaoaGGR72dPvdCJOXQAQIID143Tm1ZYgECCA79IVOx0JBlAggBDureEreiAQIIDtxXu5RTtaUCCAgSQl7l2r5J';

  static get(operation, token = '') {
    return this.doCall('GET', operation, '', token);
  }

  static post(operation, params = '', token = '') {
    return this.doCall('POST', operation, params, token);
  }

  static put(operation, params = '', token = '') {
    return this.doCall('PUT', operation, params, token);
  }

  static delete(operation, params = '', token = '') {
    return this.doCall('DELETE', operation, params, token);
  }

  static login(email, password){
    var params ={
      email: {
        value: email
      },
      passphrase: {
        value: this.hashMD5(password)
      }
    }
    return this.post("user/session", params);
  }

  static doCall(method, operation, params = '', token = '') {

    const ts = Date.now();
    const hash = `${method.toUpperCase()}${this.API_account}${this.API_version}${ts}`;
    const d = this.calculateHMACSHA256(hash, this.API_privatekey);
    const a = `v=${this.API_version};k=${this.API_account};ts=${ts};d=${d}`;

    const headers = {
      Accept: 'application/json',
      'Cache-Control': 'no-cache',
      tenantId: this.API_tenant,
      a,
    };

    if (params) {
      headers['Content-Type'] = 'application/json;charset=UTF-8';
    }

    if (token) {
      headers['token'] = token;
    }

    const url = `${this.API_endpoint}${operation}`;

    const config = {
      method,
      url,
      headers,
      data: params ? JSON.stringify(params) : null,
    };

    try {
      const response =  axios(config);
      const result = {
        code: response.status,
        data: response.data,
      };
      return result;
    } 
    catch (error) {
      return {
        code: error.response ? error.response.status : 500,
        data: error.response ? error.response.data : 'Internal Server Error',
      };
    }
}

  static calculateHMACSHA256(data, key){
    const hmacSHA256 = CryptoJS.HmacSHA256(data, key).toString();
    return hmacSHA256;
  }

  static hashMD5(data){
    return CryptoJS.MD5(data).toString();
  }

}

