import React, { useEffect, useState } from "react";
import ImageSlider from "../ImageSlider";
import "../../styles/global.css";
import "../../styles/home.css";
import BlueLine from "../../images/homepage/blue-line.png";
import Plane from "../../images/homepage/plane.jpg";
import PeopleIcon from "../../images/homepage/peopleIcon.png";
import CardComponent from "../CardComponent";
import ContactUs from "../ContactUs";
import { Link } from "react-router-dom";
import ScrollToTop from "../../utilities/ScrollToTop";
import BlogService from "../../services/blogService";
import HomePageService from "../../services/homePageService";
import RichText from "../RichText";
import RcImage from "../../attributes/RcImage";

function HomePage() {
  const [stHome, setStHome] = useState([]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    let homePageService = new HomePageService();
    homePageService
      .getStHome()
      .then((response) => setStHome(response.data.data));
  }, []);

  useEffect(() => {
    getBlogs();
  }, []);

  function getBlogs() {
    let blogService = new BlogService();
    blogService.getBlogs().then((response) => {
      let data = [];
      response.data.data.forEach((element) => {
        if (element?.attributes?.Show_on_the_homepage) {
          data.push(element);
        }
      });
      setBlogs(data);
    });
  }

  return (
    <div>
      <div className="homePage_container">
        <div className="homePageContainerInner">
          <ImageSlider
            images={stHome?.attributes?.Header_Section?.Title_Images?.data}
          ></ImageSlider>
          <div className="homePageContainerText">
            <div className="homePageHeaderContainer">
              <div className="pageHeaderText">
                <h1>
                  Where{" "}
                  <span className="leaders-meet-text">
                    <span className="leaders-text">
                      leaders <RcImage src={BlueLine}></RcImage>
                    </span>{" "}
                    meet
                  </span>
                </h1>
              </div>
              <h1
                style={{ textAlign: "right" }}
                className="pageHeaderInlineText"
              >
                {stHome?.attributes?.Header_Section?.Main_Title_2}
              </h1>
            </div>
            <div className="homepageButtons">
              {Array.isArray(stHome?.attributes?.Header_Section?.Buttons) &&
                stHome?.attributes?.Header_Section?.Buttons?.map(
                  (element, index) => (
                    <div>
                      {index % 2 === 0 ? (
                        <Link to={element.ButtonLink} target="_blank">
                          <button className="blueButton">
                            {element.ButtonText}
                          </button>
                        </Link>
                      ) : (
                        <Link to={element.ButtonLink} target="_blank">
                          <button className="whiteButton">
                            {element.ButtonText}
                          </button>
                        </Link>
                      )}
                    </div>
                  )
                )}
            </div>
          </div>
          <div className="ellipse"></div>
        </div>
      </div>
      <div className="homePageaboutUs">
        {stHome?.attributes?.Homepage_About_Us ? (
          <div>
            <div className="headerStyle" style={{ marginBottom: "50px" }}>
              {stHome?.attributes?.Homepage_About_Us?.Title}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "1px 0px 0px",
                gap: "20px",
              }}
              className="home-about-us-container"
            >
              {Array.isArray(
                stHome?.attributes?.Homepage_About_Us?.About_Us_Subheading
              ) &&
                stHome?.attributes?.Homepage_About_Us?.About_Us_Subheading?.map(
                  (element, index) => (
                    <div className="boxContainer" style={{ width: "100%" }}>
                      <div
                        style={{ textAlign: "left" }}
                        className="pageHeaderInlineText"
                      >
                        {element?.Title}
                      </div>
                      <p
                        style={{ textAlign: "left", whiteSpace: "pre-line" }}
                        className="boxParagraph"
                      >
                        <RichText data={element?.Description}></RichText>
                      </p>
                    </div>
                  )
                )}
            </div>
          </div>
        ) : (
          false
        )}
        <div
          className="homepageaboutUsImage"
          style={{
            display: "flex",
            background: `linear-gradient(92deg, rgba(0, 82, 156, 0.80) 0.97%, rgba(255, 255, 255, 0.90) 100%), url(${stHome?.attributes?.Blue_Area?.Image?.data?.attributes?.url})`,
          }}
        >
          <p
            style={{
              margin: "80px 0px 80px 46px",
              textAlign: "left",
            }}
            className="homepageaboutUsText"
          >
            {stHome?.attributes?.Blue_Area?.Blue_Area_Title}
          </p>
          {stHome?.attributes?.Blue_Area?.Button ? (
            <Link
              className="blueButtonOutline"
              target="_blank"
              style={{ marginLeft: "auto" }}
              to={stHome?.attributes?.Blue_Area?.Button?.Button_URL}
            >
              <button className="blueButton" style={{ marginRight: "20px" }}>
                {stHome?.attributes?.Blue_Area?.Button.Button_Name}
              </button>
            </Link>
          ) : (
            false
          )}
        </div>
      </div>

      <div className="homePageMembers">
        <h1 className="headerStyle">Our Members</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px",
            isolation: "isolate",
            width: "640px",
            height: "auto",
            marginTop: "60px",
            overflow: "hidden",
          }}
          className="homePageMembersInner"
        >
          <div>
            <img
              style={{ borderRadius: "50%", height: "600px", width: "100%" }}
              src={Plane}
              alt="plane"
            ></img>
          </div>
          <div className="homePageAltoText">
            <h1 className="pageHeaderInlineText">
              Learn more about ALTO’s current membership and view our members
              list.
            </h1>
            <Link to={"/members"}>
              <button
                className="blueButton"
                style={{
                  marginTop: "-20px",
                  backgroundColor: "#00529C",
                  color: "white",
                }}
              >
                {" "}
                View Members
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="homePageBecomeMember">
        <h1 className="headerStyle">How to Become a Member</h1>
        <div className="homePageBecomeMemberDiv">
          <RcImage src={PeopleIcon}></RcImage>
          <p className="boxParagraph">
            <RichText
              data={stHome?.attributes?.How_to_Become_a_Member}
            ></RichText>
          </p>
          <Link to={"/member-benefits"}>
            <button
              className="blueButton"
              style={{ backgroundColor: "#00529C", color: "white" }}
            >
              {" "}
              Join Alto
            </button>
          </Link>
        </div>
      </div>

      <div className="homePageMemories">
        <div
          className="homePageSection1"
          style={{
            background: "#00529C",
            paddingBottom: "100px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          {Array.isArray(stHome?.attributes?.Homepage_Products) &&
            stHome?.attributes?.Homepage_Products?.map((element, index) => (
              <div style={{ width: "100%" }}>
                {index % 2 === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      overflow: "hidden",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "104px 0px 56px",
                      gap: "50px",
                      width: "100%",
                      borderRadius: "40px 40px 0px 0px",
                    }}
                    className="subSection subSectionChildN"
                  >
                    <div
                      className="homePageAltoMemoryCard"
                      style={{ width: "100%" }}
                    >
                      <h1 className="headerStyle" style={{ color: "#FFFFFF" }}>
                        {element?.Title}
                      </h1>
                      <p
                        style={{
                          textAlign: "left",
                          color: "white",
                          whiteSpace: "pre-line",
                        }}
                        className="boxParagraph"
                      >
                        <RichText data={element?.Description}></RichText>
                      </p>
                      {element?.Product_Button ? (
                        <Link
                          to={element?.Product_Button?.ButtonLink}
                          target="_blank"
                        >
                          <button
                            className="whiteButton"
                            style={{
                              backgroundColor: "white",
                              color: "#00529C",
                            }}
                          >
                            {element?.Product_Button?.ButtonText}
                          </button>
                        </Link>
                      ) : (
                        false
                      )}
                    </div>
                    <div
                      className="right-rotate-images"
                      style={{ width: "50%", marginLeft: "auto" }}
                    >
                      <RcImage
                        style={{ width: "100%" }}
                        src={element?.Image?.data?.attributes?.url}
                      ></RcImage>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      overflow: "hidden",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "104px 0px 56px",
                      gap: "50px",
                      width: "100%",
                      borderRadius: "40px 40px 0px 0px",
                    }}
                    className="subSection subSectionChild2N"
                  >
                    <div
                      className="left-rotate-images"
                      style={{
                        marginTop: "30px",
                        width: "50%",
                        marginLeft: "auto",
                      }}
                    >
                      <RcImage
                        style={{ width: "100%" }}
                        src={element?.Image?.data?.attributes?.url}
                      ></RcImage>
                    </div>
                    <div
                      className="homePageAltoMemoryCard"
                      style={{ width: "100%" }}
                    >
                      <h1 className="headerStyle" style={{ color: "#FFFFFF" }}>
                        {element?.Title}
                      </h1>
                      <p
                        style={{
                          textAlign: "left",
                          color: "white",
                          whiteSpace: "pre-line",
                        }}
                        className="boxParagraph"
                      >
                        <RichText data={element?.Description}></RichText>
                      </p>
                      {element?.Product_Button ? (
                        <Link
                          to={element?.Product_Button?.ButtonLink}
                          target="_blank"
                        >
                          <button
                            className="whiteButton"
                            style={{
                              backgroundColor: "white",
                              color: "#00529C",
                            }}
                          >
                            {element?.Product_Button?.ButtonText}
                          </button>
                        </Link>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>

        <div className="homePageSection2" style={{ top: "-40px" }}>
          <div className="headerStyle">Blog</div>
          <div
            className="cardItemhomePageSection2"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-start",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            {blogs.map((file) => (
              <CardComponent key={file.id} file={file}></CardComponent>
            ))}
          </div>
          <Link to={"/blog"}>
            <button
              className="blueButton"
              style={{ backgroundColor: "#00529C", color: "white" }}
            >
              Read Our Blog
            </button>
          </Link>
        </div>
      </div>

      <ContactUs title={"Get in Touch"} page={"Home"}></ContactUs>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}

export default HomePage;
