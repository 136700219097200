import React from "react";
import "../styles/footer.css"
import { Link } from "react-router-dom";
import SocialPlatforms from "../partials/SocialPlatforms/SocialPlatforms";
import RcImage from '../attributes/RcImage';

const Footer = () => {

  function getCurrentYear(){
    return new Date().getFullYear();
  }

  return (
    <div className="footerComponentMain">
        <div className="footer-copyright-and-socials">
            <Link to={"/contact"} target="_blank"><p className="">© Copyright {getCurrentYear()} ALTO - Association of Language Travel Organisations | All rights reserved | <span>Contact</span> |</p></Link>
            <SocialPlatforms></SocialPlatforms>
        </div>
        <div className="footer-powered-by">
            <p>Powered by</p>
            <RcImage src={require("../images/footer/footer-powered-by.png")}></RcImage>
        </div>
    </div>
  );
};
export default Footer;
