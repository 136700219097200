import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "../../styles/contact.css";
import ScrollToTop from "../../utilities/ScrollToTop";
import GoogleCaptcha from "../../partials/GoogleCaptcha";
import ContactUserService from "../../services/contactUsService";
import { Spinner } from "@chakra-ui/react";
import toast from "react-hot-toast";

function ContactUs(props) {
  const { register, handleSubmit, formState, reset } = useForm();

  const [stContactUs, setStContactUs] = useState([]);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    if (captchaVerified) {
      setLoading(true);
      let contactUsService = new ContactUserService();
      await contactUsService
        .postContactUsForm(data)
        .then(() => {
          setLoading(false);
          toast.success("Success.", {
            position: "top-right",
          });
          reset();
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  function checkCaptchaIsVerified(state) {
    setCaptchaVerified(state);
  }

  useEffect(() => {
    let contactUsService = new ContactUserService();
    contactUsService
      .getStContactUs()
      .then((result) => setStContactUs(result?.data?.data));
  }, []);

  return (
    <div className="contactContainer">
      <div className="contactHeaderContainer">
        <div>
          <h1 className="contactHeader">{stContactUs?.attributes?.Title}</h1>
        </div>
        <div className="cantactInlineHeader">
          {stContactUs?.attributes?.Description}
        </div>
      </div>
      <div
        className="contactImage"
        style={{
          display: "flex",
          background: `linear-gradient(92deg, rgba(0, 82, 156, 0.80) 0.97%, rgba(255, 255, 255, 0.90) 100%), url(${stContactUs?.attributes?.Background_Image?.data?.attributes?.url})`,
        }}
      >
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="firstNameAndLastName">
            <div className="formGroup">
              <p className="inputTitle">FIRST NAME</p>
              <input
                type="text"
                {...register("firstName", { required: true })}
              ></input>
            </div>
            <div className="formGroup">
              <p className="inputTitle">LAST NAME</p>
              <input
                type="text"
                {...register("lastName", { required: true })}
              ></input>
            </div>
          </div>
          <div className="formGroup">
            <p className="inputTitle">ORGANISATION</p>
            <input
              type="text"
              {...register("organisation", { required: true })}
            ></input>
          </div>
          <div className="formGroup">
            <p className="inputTitle">EMAIL</p>
            <input
              type="email"
              {...register("email", { required: true })}
            ></input>
          </div>
          <div className="formGroup">
            <p className="inputTitle">PROFESSIONAL TITLE</p>
            <input
              type="text"
              {...register("professionalTitle", { required: true })}
            ></input>
          </div>
          <div className="formGroup">
            <p className="inputTitle">COUNTRY</p>
            <input
              type="text"
              {...register("country", { required: true })}
            ></input>
          </div>
          <div className="formGroup">
            <p className="inputTitle">NOTE</p>
            <input
              type="text"
              {...register("note", { required: true })}
            ></input>
          </div>
          <div className="formGroup">
            <GoogleCaptcha
              checkCaptchaIsVerified={checkCaptchaIsVerified}
            ></GoogleCaptcha>
          </div>

          <button
            type="submit"
            className="default-button"
            disabled={loading || !formState.isValid}
          >
            Send {loading && <Spinner size="sm" />}
          </button>
        </form>
      </div>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}

export default ContactUs;
