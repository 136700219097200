import React from "react";
import "../styles/cardComponent.css";

function PastEventsCard(props) {
  return (
    <div className="pastEventCardComponent">
      <div>
        <img
          style={{
            width: "400px",
            height: "240px",
            borderRadius: "15px",
            mixBlendMode: "luminosity",
            opacity: "0.5",
          }}
          src={props.file.imageSrc}
          alt="cardImg1"
        ></img>
      </div>
      <div>
        <p className="dateEvents">{props.file.date}</p>
        <h1 className="pastEventCardComponentHeader">{props.file.title}</h1>
        <p className="cardText">{props.file.text}</p>
      </div>
      <p className="textButtonCard">View Details</p>
    </div>
  );
}

export default PastEventsCard;
