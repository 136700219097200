import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./components/pages/HomePage";
import JoinAlto from "./components/pages/JoinAlto";
import AboutUs from "./components/pages/AboutUs";
import Events from "./components/pages/Events";
import NewsandProjects from "./components/pages/NewsandProjects";
import ContactUs from "./components/pages/ContactUs";
import Members from "./components/pages/Members";
import SignIn from "./components/pages/SignIn";
import JoinNow from "./components/pages/JoinNow";
import TrendsBarometer from "./components/pages/TrendsBarometer";
import Blog from "./components/pages/Blog";
import NewsAndProjectDetail from "./components/pages/NewsAndProjectDetail";
import EventsDetail from "./components/pages/EventsDetail";
import BlogDetail from "./components/pages/BlogDetail";
import EventRegister from "./components/pages/EventRegister";
import { Toaster } from "react-hot-toast";
import { StaticPage } from "./components/pages/StaticPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ChakraProvider>
          <Toaster />
          <Header></Header>
          <Routes>
            <Route path="/" element={<HomePage></HomePage>}></Route>
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/detail/:slug" element={<EventsDetail />} />
            <Route path="/events/register/:slug" element={<EventRegister />} />
            <Route path="/projects" element={<NewsandProjects />} />
            <Route path="/member-benefits" element={<JoinAlto />} />
            <Route path="/how-to-join-alto" element={<JoinNow />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/members" element={<Members />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/detail/:slug" element={<BlogDetail />} />
            {/*<Route path="/members_area" element={<SignIn />} />*/}
            <Route path="/trends_barometer" element={<TrendsBarometer />} />
            <Route
              path="/projects/detail/:slug"
              element={<NewsAndProjectDetail />}
            />
            <Route path="/:slug" element={<StaticPage />} />
          </Routes>
          <Footer></Footer>
        </ChakraProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
