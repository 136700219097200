import React from "react";
import "../styles/cardComponent.css";
import { Link } from "react-router-dom";

function CardComponent(props) {

  return (
    <div className="cardComponent">
      <div
      className="cardComponentImage"
        style={{
          width: "100%",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
          }}
          src={props.file?.attributes?.Blog_Main_Image?.data?.attributes?.url}
          alt="cardImg"
        ></img>
      </div>
      <div className="cardComponentContentText">
        <p className="cardComponentFileDate">{props.file?.attributes?.Blog_Date}</p>
        <Link to={`/blog/detail/${props?.file?.attributes?.url}`}><h1 className="cardComponentFileTitle" style={{cursor: "pointer"}}>{props.file?.attributes?.Blog_Title}</h1></Link>
        <p className="cardComponentFileText">{props.file?.attributes?.BlogShortDescription}</p>
      </div>
    </div>
  );
}

export default CardComponent;
