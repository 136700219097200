import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../styles/newsAndProjectDetail.css";
import ScrollToTop from "../../utilities/ScrollToTop";
import NewsAndProjectService from "../../services/newsAndProjectService";
import Navigation from "../Navigation";
import RichText from "../RichText";
import RcImage from "../../attributes/RcImage";
import RcLink from "../../attributes/RcLink";
import { useForm } from "react-hook-form";
import ContactUserService from "../../services/contactUsService";
import toast from "react-hot-toast";
import GoogleCaptcha from "../../partials/GoogleCaptcha";
import { Spinner } from "@chakra-ui/react";

export default function NewsAndProjectDetail() {
  const { register, handleSubmit, formState, reset } = useForm();

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    if (captchaVerified) {
      setLoading(true);
      let contactUsService = new ContactUserService();
      delete data["permission"];
      await contactUsService
        .postContactUsForm(data)
        .then(() => {
          setLoading(false);
          toast.success("Success.", {
            position: "top-right",
          });
          reset();
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  function checkCaptchaIsVerified(state) {
    setCaptchaVerified(state);
  }

  let { slug } = useParams();

  const [newsAndProjects, setNewsAndProjects] = useState({});

  useEffect(() => {
    let newsAndProejctsService = new NewsAndProjectService();
    newsAndProejctsService
      .getNewsAndProjectBySlug(slug)
      .then((result) => setNewsAndProjects(result.data.data[0]));
  }, [slug]);

  return (
    <div className="news-and-project-detail-main">
      <div className="main-container">
        <div className="left-section">
          <div className="title">
            <p>{newsAndProjects?.attributes?.Main_Title_1}</p>
          </div>
          {newsAndProjects?.attributes?.Main_Title_2 ? (
            <div className="sub-title">
              <p>{newsAndProjects?.attributes?.Main_Title_2}</p>
            </div>
          ) : (
            false
          )}
          <div className="description">
            <p style={{ whiteSpace: "pre-line" }}>
              <RichText
                data={newsAndProjects?.attributes?.Main_Description}
              ></RichText>
            </p>
          </div>
          {newsAndProjects?.attributes?.Main_Button ||
          newsAndProjects?.attributes?.Main_Link ? (
            <div>
              {newsAndProjects?.attributes?.Main_Button ? (
                <div className="please-complete-button">
                  <Link
                    to={newsAndProjects?.attributes?.Main_Button?.ButtonLink}
                    target="_blank"
                  >
                    <button className="default-button">
                      {newsAndProjects?.attributes?.Main_Button?.ButtonText}
                    </button>
                  </Link>
                </div>
              ) : (
                false
              )}
              {newsAndProjects?.attributes?.Main_Link ? (
                <div className="main-link">
                  <RcLink
                    href={newsAndProjects?.attributes?.Main_Link?.Main_Link_URL}
                    text={
                      newsAndProjects?.attributes?.Main_Link?.Main_Link_Name
                    }
                  ></RcLink>
                </div>
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}
          {/* <d iv className='links'>
                    <Link className='link'><a>Get the ALTO Industry Pulse Q4 2021 Report (Oct/Nov 2021)</a></Link>
                    <Link className='link'><a>Get the ALTO Industry Pulse Q3 2021 Report (July 2021)</a></Link>
                    <Link className='link'><a>Get the ALTO Industry Pulse Q2 2021 Report (April 2021)</a></Link>
                    <Link className='link'><a>Get the ALTO Industry Pulse Q1 2021 Report (January 2021)</a></Link>
                    <Link className='link'><a>Get the ALTO Industry Pulse Q4 2020 Report (October 2020)</a></Link>
                </d> */}
        </div>
        <div className="right-section">
          <div className="image-content">
            <RcImage
              src={
                newsAndProjects?.attributes?.Main_Image?.data?.attributes?.url
              }
            ></RcImage>
          </div>
        </div>
      </div>
      {newsAndProjects?.attributes?.Subheading_Title ||
      newsAndProjects?.attributes?.Subheading_Description ? (
        <div className="sub-headings">
          {newsAndProjects?.attributes?.Subheading_Title ? (
            <div className="sub-title">
              <p style={{ whiteSpace: "pre-line" }}>
                <RichText
                  data={newsAndProjects?.attributes?.Subheading_Title}
                ></RichText>
              </p>
            </div>
          ) : (
            false
          )}
          {newsAndProjects?.attributes?.Subheading_Description ||
          newsAndProjects?.attributes?.Subheading_Button ? (
            <div className="sub-description">
              {newsAndProjects?.attributes?.Subheading_Description ? (
                <p style={{ whiteSpace: "pre-line" }}>
                  <RichText
                    data={newsAndProjects?.attributes?.Subheading_Description}
                  ></RichText>
                </p>
              ) : (
                false
              )}
              {newsAndProjects?.attributes?.Subheading_Button ? (
                <Link
                  to={
                    newsAndProjects?.attributes?.Subheading_Button
                      ?.Subeheading_Button_URL
                  }
                  target="_blank"
                >
                  <button className="default-button">
                    {
                      newsAndProjects?.attributes?.Subheading_Button
                        ?.Subeheading_Button_Name
                    }
                  </button>
                </Link>
              ) : (
                false
              )}
              {newsAndProjects?.attributes?.Subheading_Link ? (
                <div className="sub-heading-link">
                  <RcLink
                    href={
                      newsAndProjects?.attributes?.Subheading_Link
                        ?.Subheading_Link_URL
                    }
                    text={
                      newsAndProjects?.attributes?.Subheading_Link
                        ?.Subheading_Link_Name
                    }
                  ></RcLink>
                </div>
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}
        </div>
      ) : (
        false
      )}

      {newsAndProjects?.attributes?.Add_Contact_Form_to_This_Page ? (
        <div className="contact-container">
          <div className="inner">
            <div className="title">
              <p>ALTO Members Area</p>
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="firstNameAndLastName">
                <div className="formGroup">
                  <p className="inputTitle">FIRST NAME</p>
                  <input
                    type="text"
                    {...register("firstName", { required: true })}
                  ></input>
                </div>
                <div className="formGroup">
                  <p className="inputTitle">LAST NAME</p>
                  <input
                    type="text"
                    {...register("lastName", { required: true })}
                  ></input>
                </div>
              </div>
              <div className="formGroup">
                <p className="inputTitle">ORGANISATION</p>
                <input
                  type="text"
                  {...register("organisation", { required: true })}
                ></input>
              </div>
              <div className="formGroup">
                <p className="inputTitle">DIRECT EMAIL</p>
                <input
                  type="email"
                  {...register("email", { required: true })}
                ></input>
              </div>
              <div className="formGroup">
                <p className="inputTitle">PROFESSIONAL TITLE</p>
                <input
                  type="text"
                  {...register("professionalTitle", { required: true })}
                ></input>
              </div>
              <div className="formGroup">
                <p className="inputTitle">COUNTRY</p>
                <input
                  type="text"
                  {...register("country", { required: true })}
                ></input>
              </div>
              <div className="formGroup">
                <p className="inputTitle">YOUR COMMENTS</p>
                <input
                  type="text"
                  {...register("note", { required: true })}
                ></input>
              </div>
              <div className="formGroup">
                <GoogleCaptcha
                  checkCaptchaIsVerified={checkCaptchaIsVerified}
                ></GoogleCaptcha>
              </div>
              <div className="permission formGroup">
                <div>
                  <input
                    id="checkPermission"
                    type="checkbox"
                    {...register("permission", { required: true })}
                  ></input>
                </div>
                <div>
                  <label for="checkPermission">
                    By submitting this form I declare that I hold a valid
                    membership with ALTO. I have read and understand the ALTO
                    Best Practice Guidelines and agree to implement the
                    framework in my organisation. I will feature the members'
                    stamp on my digital and printed materials to promote the
                    Guidelines to my partners.*
                  </label>
                </div>
              </div>
              <div className="bottom-description">
                <p>
                  *In case you don't agree with the statement above please
                  discontinue with the form. The ALTO Best Practice Guidelines
                  are not compulsory for members, thank you.
                </p>
              </div>

              <button
                type="submit"
                disabled={loading || !formState.isValid}
                className="btnSend"
              >
                Send {loading && <Spinner size="sm" />}
              </button>
            </form>
          </div>
        </div>
      ) : (
        false
      )}

      <div className="navigation-bottom">
        <Navigation></Navigation>
      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
}
