import React, { useState, useEffect } from "react";
import "../../styles/global.css";
import "../../styles/joinAlto.css";
import Vector from "../../images/joinus/Vector.png";
import ContactUs from "../ContactUs";
import { Link } from "react-router-dom";
import ScrollToTop from "../../utilities/ScrollToTop";
import JoinAltoService from "../../services/joinAltoService";
import { NumericFormat } from "react-number-format";
import RichText from "../RichText";
import RcLink from "../../attributes/RcLink";
import RcImage from "../../attributes/RcImage";

function JoinAlto() {
  const [joinAlto, setJoinAlto] = useState({});

  useEffect(() => {
    let joinAltoService = new JoinAltoService();
    joinAltoService.getJoinAlto().then((result) => {
      setJoinAlto(result.data.data);
      setCurrentSpecialOfferTab(specialOfferTabs[0]);
      let data = [];
      let allOffers =
        result.data.data?.attributes?.Special_Offers_for_Members
          ?.Special_Offers;
      if (Array.isArray(allOffers) && allOffers.length > 0) {
        data = allOffers.filter((o) => o.Type === specialOfferTabs[0].name);
      }
      setCurrentSpecialOffers(data);
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const specialOfferTabs = [{ id: 2, name: "Schools" }];

  const [currentSpecialOfferTab, setCurrentSpecialOfferTab] = useState({});
  const [currentSpecialOffers, setCurrentSpecialOffers] = useState([]);

  function getCurrentSpecialOfferTabClass(id) {
    if (currentSpecialOfferTab.id === id) {
      return "tab";
    }
    return "tab passive-tab";
  }

  function calculateDateIsPassed(date) {
    var currentDate = new Date();
    var paramDate = new Date(date);
    var result = currentDate.getTime() >= paramDate.getTime();
    return result;
  }

  function getPassiveSpecialOfferClass(date) {
    if (calculateDateIsPassed(date)) {
      return "headings-column passive";
    }
    return "headings-column";
  }

  function setSpecialOffer(index) {
    setCurrentSpecialOfferTab(specialOfferTabs[index]);
    let data = [];
    let allOffers =
      joinAlto?.attributes?.Special_Offers_for_Members?.Special_Offers;
    if (Array.isArray(allOffers) && allOffers.length > 0) {
      data = allOffers.filter((o) => o.Type === specialOfferTabs[index].name);
    }
    setCurrentSpecialOffers(data);
  }

  function getTotalSavings() {
    let total = 0;
    currentSpecialOffers.forEach((element) => {
      if (!calculateDateIsPassed(element.Expiration_Date)) {
        total += element.Savings;
      }
    });
    return total;
  }

  const scroll = (top) => {
    window.scrollTo({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="join-alto-main">
      <div
        style={{
          width: "100%",
          height: "640px",
          backgroundImage: `url(${joinAlto?.attributes?.Header_Section_Join_Alto?.Main_Image?.data?.attributes?.url})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundClip: "content-box",
        }}
        className="joinAltoDiv"
      >
        <div className="joinAltoDivEllipseBlue"></div>
        <div
          style={{
            padding: "0px 800px 0px 80px",
            gap: "40px",
            height: "261px",
          }}
          className="joinAltoTextComponent"
        >
          <div className="joinAltoTextComponent">
            <h1 style={{ color: "white" }} className="joinAltoHeader1">
              {
                joinAlto?.attributes?.Header_Section_Join_Alto
                  ?.Main_Title_Join_Alto
              }
            </h1>
            <h1
              style={{ color: "white", textAlign: "left" }}
              className="joinAltoHeader2"
            >
              {
                joinAlto?.attributes?.Header_Section_Join_Alto
                  ?.Main_Title_Join_Alto_2
              }
            </h1>
          </div>
          {joinAlto?.attributes?.Header_Section_Join_Alto?.Main_Button ? (
            <Link
              to={
                joinAlto?.attributes?.Header_Section_Join_Alto?.Main_Button
                  ?.ButtonLink
              }
            >
              <button className="whiteButtonJoinAlto">
                {
                  joinAlto?.attributes?.Header_Section_Join_Alto?.Main_Button
                    ?.ButtonText
                }
              </button>
            </Link>
          ) : (
            false
          )}
        </div>
      </div>

      <div
        style={{
          width: "100%",
        }}
        className="joinAltoDiv2"
      >
        <div
          style={{
            width: "100%",
            padding: "56px 80px 96px",
            borderRadius: " 40px 40px 0px 0px",
            background: "white",
          }}
          className="why-should-join-alto"
        >
          <h1
            className="headerStyle"
            style={{
              position: "relative",
            }}
          >
            {joinAlto?.attributes?.Join_Alto_Description?.Title}
          </h1>
          <p style={{ marginTop: "40px" }} className="boxParagraph">
            <RichText
              data={joinAlto?.attributes?.Join_Alto_Description?.Description}
            ></RichText>
          </p>

          {joinAlto?.attributes?.Join_Alto_Description?.Benefits.length > 0 ? (
            <div
              style={{ marginTop: "100px", gap: "50px", marginBottom: "120px" }}
              className="why-should-join-alto-items-dart-box"
            >
              {Array.isArray(
                joinAlto?.attributes?.Join_Alto_Description?.Benefits
              ) &&
                joinAlto?.attributes?.Join_Alto_Description?.Benefits.map(
                  (benefit, index) => (
                    <div
                      key={index}
                      className="why-should-join-alto-items-dart-box-vectorBox"
                    >
                      <img src={Vector} alt="vector"></img>
                      <p className="boxParagraph">
                        <RichText data={benefit?.Benefits}></RichText>
                      </p>
                    </div>
                  )
                )}
            </div>
          ) : (
            false
          )}

          <h1 className="joinAltoHeader2" style={{ marginTop: "50px" }}>
            The ALTO Board is Also Committed To:
          </h1>

          <div style={{ width: "100%" }} className="commitBox">
            <div
              className="commitBoxSubDiv"
              style={{
                padding: "20px 40px 0px",
                gap: "10px",
                marginTop: "30px",
              }}
            >
              <p className="boxParagraph">
                Fostering an awareness of ESG (Environmental, Social, and
                Governance) and sustainability amongst members through various
                educational seminars.
              </p>
              <div className="divider"></div>
            </div>
            <div
              className="commitBoxSubDiv"
              style={{ padding: "20px 40px 0px", gap: "10px" }}
            >
              <p className="boxParagraph">
                Attracting and developing the leaders of tomorrow so as to
                ensure the succession, sustainability and growth of our
                industry.
              </p>
              <div className="divider"></div>
            </div>
            <div
              className="commitBoxSubDiv"
              style={{ padding: "20px 40px 0px", gap: "10px" }}
            >
              <p className="boxParagraph">
                Working with national and international associations to help
                provide them with a global perspective on the industry (we have
                18 association members).
              </p>
              <div className="divider"></div>
            </div>
            <div
              className="commitBoxSubDiv"
              style={{ padding: "20px 40px 0px", gap: "10px" }}
            >
              <p className="boxParagraph">
                Raising the international profile of the language and
                educational travel industry within the global economy through
                benchmarking.
              </p>
            </div>
            <Link to={"/about-us"}>
              <button
                className="blueButton"
                style={{
                  backgroundColor: "#00529C",
                  color: "white",
                  marginTop: "50px",
                }}
              >
                View Alto Board
              </button>
            </Link>
          </div>
          <h1 style={{ marginTop: "100px" }} className="joinAltoHeader2">
            <RichText
              data={joinAlto?.attributes?.Special_Offers_for_Members?.Title}
            ></RichText>
          </h1>
          <p style={{ marginTop: "30px" }} className="boxParagraph">
            <RichText
              data={
                joinAlto?.attributes?.Special_Offers_for_Members?.Description
              }
            ></RichText>
          </p>

          {joinAlto?.attributes?.Special_Offers_for_Members?.Special_Offers
            .length > 0 ? (
            <div className="offerBox">
              <div className="tab-buttons">
                {/*  {specialOfferTabs.map((element, index) => (
                  <div onClick={() => setSpecialOffer(index)} className={getCurrentSpecialOfferTabClass(element.id)}>
                    <span>{element.name}</span>
                  </div>
                ))}*/}
              </div>
              <div className="inner">
                <div className="offerBoxInner" style={{ padding: "40px" }}>
                  <div className="headings-row">
                    <h1 style={{ fontWeight: "700" }}>SPECIAL OFFERS</h1>
                    <h1 style={{ fontWeight: "700" }}>EXPIRES</h1>
                    <h1 style={{ fontWeight: "700" }}>SAVINGS</h1>
                  </div>
                  <div>
                    {currentSpecialOffers.map((specialOffer, index) => (
                      <div
                        key={index}
                        className={getPassiveSpecialOfferClass(
                          specialOffer?.Expiration_Date
                        )}
                      >
                        <div className="special-offer-description">
                          <RcLink
                            href={specialOffer?.Name_URL}
                            text={specialOffer?.Name}
                            className="title"
                          ></RcLink>
                          <p className="description">
                            <RichText
                              data={specialOffer?.Description}
                            ></RichText>
                          </p>
                        </div>
                        <p className="special-offer-expires">
                          {specialOffer?.Expiration_Date}
                        </p>
                        <p className="special-offer-savings">
                          <NumericFormat
                            value={specialOffer?.Savings}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"€"}
                          />
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="totalDiv">
                  <div className="totalDescription">
                    <p style={{ fontWeight: "600" }}>TOTAL SAVINGS</p>
                    {joinAlto?.attributes?.Special_Offers_for_Members?.Text}
                  </div>
                  <p className="totalAmount">{getTotalSavings()} EURO</p>
                </div>
              </div>
            </div>
          ) : (
            false
          )}

          <p style={{ marginTop: "40px" }} className="boxParagraph">
            When you add up these discounts, your savings are actually higher
            than the cost of membership!
          </p>
        </div>
      </div>

      <div
        className="joinAltoDiv2 howtoJoinAltoJoinAltoDiv"
        style={{
          padding: "56px 80px",
          gap: "40px",
          background: "#00529C",
          marginTop: "50px",
        }}
      >
        <h1 style={{ color: "white" }} className="headerStyle">
          {" "}
          {joinAlto?.attributes?.How_to_Join_Alto?.Main_Title}
        </h1>
        <p style={{ color: "white" }} className="boxParagraph">
          {joinAlto?.attributes?.How_to_Join_Alto?.Description}
        </p>
        <div className="howtoJoinAltoJoinAltoDivSampleDiv stepper-wrapper">
          {Array.isArray(joinAlto?.attributes?.How_to_Join_Alto?.Join_Steps) &&
            joinAlto?.attributes?.How_to_Join_Alto?.Join_Steps.map(
              (element, index) => (
                <div class="stepper-item">
                  <div class="step-counter">{index + 1}</div>
                  <div class="step-name">
                    <RichText data={element.Description}></RichText>
                  </div>
                </div>
              )
            )}
        </div>
        <Link to={"/how-to-join-alto"} target="_blank">
          <button
            className="blueButton"
            style={{
              color: "#00529C",
              backgroundColor: "white",
              width: "240px",
              marginBottom: "60px",
            }}
          >
            Join Now !
          </button>
        </Link>
        <h1 style={{ color: "white" }} className="joinAltoHeader2">
          {joinAlto?.attributes?.Membership_Categories?.Title}
        </h1>
        <p
          style={{ color: "white", marginTop: "-30px" }}
          className="boxParagraph"
        >
          {joinAlto?.attributes?.Membership_Categories?.Description}
        </p>

        {joinAlto?.attributes?.Membership_Categories?.Category_Type.length >
        0 ? (
          <div style={{ gap: "20px" }} className="sampleDiv blueBoxContainers">
            {Array.isArray(
              joinAlto?.attributes?.Membership_Categories?.Category_Type
            ) &&
              joinAlto?.attributes?.Membership_Categories?.Category_Type.map(
                (element, index) => (
                  <div key={index} className="blueBoxContainer">
                    <h1
                      style={{ color: "#FFFFFF" }}
                      className="joinAltoHeader2"
                    >
                      {element?.Membership_Category_Name}
                    </h1>
                    <p
                      style={{ marginTop: "20px" }}
                      className="homepageaboutUsText"
                    >
                      <RichText
                        data={element?.Membership_Category_Description}
                      ></RichText>
                    </p>
                    <div style={{ marginTop: "25px" }} className="sampleDiv">
                      <div
                        style={{
                          color: "white",
                          position: "relative",
                          left: "-5px",
                        }}
                        className="headerStyle"
                      >
                        €
                      </div>
                      <h1
                        style={{
                          color: "white",
                          position: "relative",
                          top: "12px",
                          left: "5px",
                        }}
                        className="joinAltoHeader2"
                      >
                        {element.Price} EUR
                      </h1>
                      <p
                        style={{
                          color: "white",
                          top: "16px",
                          position: "relative",
                          left: "10px",
                        }}
                      >
                        per year
                      </p>
                    </div>
                  </div>
                )
              )}
          </div>
        ) : (
          false
        )}

        <button
          onClick={() => scroll(4930)}
          className="blueButton"
          style={{
            color: "#00529C",
            backgroundColor: "white",
            marginBottom: "30px",
          }}
        >
          See Membership Criteria
        </button>
        <h1
          style={{ color: "#FFFFFF", marginBottom: "-20px", marginTop: "20px" }}
          className="joinAltoHeader2"
        >
          Annual Membership Fees
        </h1>
        <p style={{ color: "#FFFFFF" }} className="boxParagraph">
          ALTO membership fees are valid for a calendar year from 1st January –
          31st December of each year.<br></br> For new members joining the
          association during the year, there is a first year pro rata membership
          fee reduction, please ask for more details during registration.
        </p>
        <h1
          style={{ color: "#FFFFFF", marginBottom: "-20px", marginTop: "50px" }}
          className="joinAltoHeader2"
        >
          How To Pay Your Membership Fee
        </h1>
        <p style={{ color: "#FFFFFF" }} className="boxParagraph">
          Please make payment using your invoice number as reference to our EUR
          bank account:
        </p>
        {joinAlto?.attributes?.BankInformations ? (
          <div className="borderBox">
            <p style={{ textAlign: "left" }} className="boxParagraph">
              <RichText data={joinAlto.attributes?.BankInformations}></RichText>
            </p>
          </div>
        ) : (
          false
        )}
      </div>

      {joinAlto?.attributes?.Membership_Criterias.length > 0 ? (
        <div className="membershipCriteria">
          <h2>Membership Criteria</h2>
          <p className="description-h2">
            In order to join ALTO, applicants must meet the entry criteria
            outlined below according to each membership category.
          </p>
          <div className="items">
            {Array.isArray(joinAlto?.attributes?.Membership_Criterias) &&
              joinAlto?.attributes?.Membership_Criterias.map((item, index) => (
                <div key={index} className="item">
                  <div className="imageAndDescription">
                    <div className="membershipCriteriaImage">
                      <RcImage
                        src={item?.Image?.data?.attributes?.url}
                      ></RcImage>
                      <div className="title" style={{ width: "100%" }}>
                        <h3>{item?.Membership_Type_Name}</h3>
                      </div>
                    </div>
                    <div className="membershipCriteriaDescription">
                      <p>
                        <RichText
                          data={item?.Membership_Type_Description}
                        ></RichText>
                      </p>
                      <div className="bottom">
                        <Link to={"/how-to-join-alto"} target="_blank">
                          View Criteria<span>&#62;</span>
                        </Link>
                        <Link
                          className="btnJoinNow"
                          style={{ marginLeft: "auto" }}
                          to={item?.Button?.ButtonLink}
                        >
                          <button>{item?.Button?.ButtonText}</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <p style={{ marginTop: "80px" }}>
            Membership criteria valid from Nov 2018. Covid19 update in 2022.
          </p>
        </div>
      ) : (
        false
      )}

      <ContactUs
        title={"Questions?"}
        description={
          "To discuss your membership application, please contact us anytime. In order for us to work on membership drive more efficiently, we would like to know where you have heard about us. An online advert, an industry event or perhaps a colleague from the industry? Please let us know, thank you!"
        }
        page={"Join Alto"}
      ></ContactUs>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}

export default JoinAlto;
