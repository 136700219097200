const ServerEnums = {
  Hosting: process.env.REACT_APP_API,
  Local: "http://localhost:1337",
  XKEY: process.env.REACT_APP_XKEY,
};

export default class Constants {
  static apiServerBasePath = ServerEnums.Hosting;
  static apiServerPath = `${ServerEnums.Hosting}/api/`;
  static xKey = ServerEnums.XKEY;

  /*
    static apiServerBasePath = ServerEnums.Local;
    static apiServerPath = `${ServerEnums.Local}/api/`;*/
}
