export default class SpecialOffer{

    constructor(title, link, description, expires, savings, state){
        this.title = title;
        this.link = link;
        this.description = description;
        this.expires = expires;
        this.savings = savings;
        this.state = state
    }
}