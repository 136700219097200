import React, { useEffect, useState } from "react";
import "../styles/navigation.css";
import { Link } from "react-router-dom";
import EventsService from "../services/eventsService";
import RichText from "./RichText";
import RcImage from "../attributes/RcImage";

export default function Navigation() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    let eventsService = new EventsService();
    eventsService.getEvents().then((result) => setEvents(result.data.data));
  }, []);

  function getUpcomingEvents(events) {
    let currentDate = new Date();
    let data = [];
    events.forEach((element) => {
      let dateParam = new Date(element?.attributes?.Event_ID?.Event_End_Date);
      if (currentDate <= dateParam) {
        data.push(element);
      }
    });
    return data;
  }

  return (
    <div className="navigation-main">
      <div className="navigation-main-inner">
        <p className="title">Upcoming Events</p>
        <div className="content">
          <div className="items">
            {getUpcomingEvents(events).map((eventData, index) => (
              <div className="item">
                <div className="image">
                  <RcImage
                    src={
                      eventData?.attributes?.Event_ID?.Event_Main_Image?.data
                        ?.attributes?.url
                    }
                  ></RcImage>
                </div>
                <div className="date-value">
                  <p>{eventData?.attributes?.Event_ID?.Event_End_Date}</p>
                </div>
                <div className="data">
                  <Link to={`/events/detail/${eventData?.attributes?.url}`}>
                    <p className="title">
                      {eventData?.attributes?.Event_ID?.Event_Name}
                    </p>
                  </Link>
                  <p className="description">
                    <RichText
                      data={eventData?.attributes?.Event_ID?.Event_Description}
                    ></RichText>
                  </p>
                  <Link
                    to={eventData?.attributes?.register_form_url}
                    target="_blank"
                  >
                    <button className="default-button">Register</button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
