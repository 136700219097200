import React from "react";
import "../styles/cardComponent.css";
import "../styles/eventsCard.css";
import { useNavigate } from "react-router-dom";
import RichText from "./RichText";

function EventsCard(props) {
  const navigate = useNavigate();

  const navigateToRoute = (path) => {
    navigate(path);
  };

  function eventDateIsPassedClass(date) {
    let currentDate = new Date();
    let dateParam = new Date(date);
    if (currentDate > dateParam) {
      return "passive-image";
    }
    return "";
  }

  function eventDateIsPassed(date) {
    let currentDate = new Date();
    let dateParam = new Date(date);
    if (currentDate > dateParam) {
      return true;
    }
    return false;
  }

  return (
    <div className="eventsCardComponent">
      <div
        onClick={() =>
          navigateToRoute(`/events/detail/${props.file?.attributes?.url}`)
        }
        style={{ cursor: "pointer" }}
      >
        <div
          className="eventsCardComponentImage"
          style={{
            width: "100%",
          }}
        >
          <img
            className={eventDateIsPassedClass(
              props?.file?.attributes?.Event_ID?.Event_End_Date
            )}
            style={{
              width: "100%",
              minHeight: "300px",
              maxHeight: "300px",
              borderRadius: "15px",
            }}
            src={
              props?.file?.attributes?.Event_ID?.Event_Main_Image?.data
                ?.attributes?.url
            }
            alt="cardImg1"
          ></img>
        </div>
        <div>
          <p className="dateEvents">
            {props?.file?.attributes?.Event_ID?.Event_Date}
          </p>
          <h1 className="cardHeader" style={{ cursor: "pointer" }}>
            {props?.file?.attributes?.Event_ID?.Event_Name}
          </h1>
          <p className="cardText">
            <RichText
              data={props?.file?.attributes?.Event_ID?.Event_Short_Description}
            ></RichText>
          </p>
        </div>
      </div>

      {!eventDateIsPassed(props?.file?.attributes?.Event_ID?.Event_End_Date) ? (
        <button
          onClick={() =>
            window.open(props.file?.attributes?.register_form_url, "_blank")
          }
          style={{ marginTop: "20px" }}
          className="default-button"
        >
          Register
        </button>
      ) : (
        <p
          onClick={() =>
            navigateToRoute(`/events/detail/${props.file?.attributes?.url}`)
          }
          style={{
            color: "#00529C",
            fontWeight: "700",
            fontSize: "18px",
            marginTop: "20px",
            textDecoration: "underline",
            textUnderlineOffset: "7px",
            cursor: "pointer",
          }}
        >
          View Details
        </p>
      )}
    </div>
  );
}

export default EventsCard;
