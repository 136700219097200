import axios from "axios";
import Constants from "../constants/constants";

export default class BlogService {
  getBlogs() {
    let populate = [
      "Blog_Title",
      "Blog_Category",
      "Blog_Date",
      "Show_on_the_blog_page",
      "Show_on_the_homepage",
      "Blog_Main_Image",
      "Blog_Paragraphs",
    ];
    return axios.get(
      `${Constants.apiServerPath}add-blogs/?populate=${populate}&sort=Blog_Date:desc&pagination[pageSize]=1000`
    );
  }

  getBlogById(id) {
    let populate = [
      "Blog_Title",
      "Blog_Category",
      "Blog_Date",
      "Show_on_the_blog_page",
      "Show_on_the_homepage",
      "Blog_Main_Image",
      "Blog_Paragraphs.Media",
    ];
    return axios.get(
      `${Constants.apiServerPath}add-blogs/${id}/?populate=${populate}`
    );
  }

  getStBlog() {
    return axios.get(`${Constants.apiServerPath}st-blog/?populate=*`);
  }
  getBlogBySlug(slug) {
    let populate = [
      "Blog_Title",
      "Blog_Category",
      "Blog_Date",
      "Show_on_the_blog_page",
      "Show_on_the_homepage",
      "Blog_Main_Image",
      "Blog_Paragraphs.Media",
    ];
    return axios.get(
      `${Constants.apiServerPath}add-blogs/?filters[url][$eq]=${slug}&populate=${populate}`
    );
  }
}
