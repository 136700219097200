import React from "react";

import "../styles/peopleCardComponent.css"

function PeopleCardComponent(props) {
  return (
    <div className="peopleCardComponent">
      <div
        style={{
          width: "413.33px",
          height: "629px",
          overflow: "hidden",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "15px 15px 0px 0px",
          }}
          src={props.file.imageSrc}
          alt="cardImg"
        ></img>
      </div>
      <div className="cardContent">
        <p className="title">{props.file.name}</p>
        <p className="dateText">{props.file.dateBetween}</p>
        <div className="description" style={{ height: "96px", overflow: "hidden", marginTop:"20px" }}>
          <p>{props.file.text}</p>
        </div>
        <div className="readMore">
          <p>Read more</p>
        </div>
        <button className="default-button w-100" style={{marginTop:"20px"}}>
          Connect on Linkedin
        </button>
      </div>
    </div>
  );
}

export default PeopleCardComponent;
