import React from 'react'
import "../../styles/signIn.css"
import altoImage from "../../images/signIn/alto.png"
import warningCircleIcon from "../../icons/warning-circle-icon.svg"
import { useState, useEffect } from "react";
import ScrollToTop from '../../utilities/ScrollToTop';
import RcImage from '../../attributes/RcImage'
import { GlueUpAPIService } from '../../services/glueUpAPIService';
import { Formik, Form } from 'formik';
import HtTextInput from '../../utilities/customFormControls/HtTextInput';
import * as yup from "yup";
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function SignIn() {

  let state = false;

  const tabs = [{id: 1, name: "Login"}];

  const [currentTab, setCurrentTab] = useState({})  

  const loginFormInitialValues = { email: "", password: "" };
  const loginFormvalidationSchema = yup.object({
    email: yup.string().required("Email boş bırakılamaz"),
    password: yup.string().required("Parola boş bırakılamaz"),
  });

  useEffect(() => {
    setCurrentTab(tabs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function login({email, password}){
     // GlueUpAPIService.get("public/membership/companyApplicationForm").then(response => console.log(response));
     GlueUpAPIService.login(email, password);
  }


  return (
    <div className='main-sign-in'>
        <div className='left-content'>
            <RcImage src={altoImage}></RcImage>
        </div>
        <div className='right-content'>
          {currentTab.id === 1 && (
            <div className='form sign-in-form-container'>
              <div className='form-title'>
                <h2>Sign in to your member account</h2>
              </div>
              <Formik 
                initialValues={loginFormInitialValues}
                validationSchema={loginFormvalidationSchema}
                onSubmit={(values) => {
                  login(values)
                }}
              >
                <div className='form-inner'>
                  {state ? (
                    <div className='warning-box'>
                        <div className='icon'>
                          <img src={warningCircleIcon} draggable='false' alt='warning-icon'></img>
                        </div>
                        <div className='texts'>
                          <p className='warning-title'>Wrong username or password</p>
                          <p className='warning-description'>Please check if you entered them correctly.</p>
                        </div>
                    </div>
                  ): false}
                  <Form>
                    <div className='formGroup'>
                      <p className='inputTitle'>USER NAME OR EMAIL</p>
                      <HtTextInput type="email" name="email"></HtTextInput>
                    </div>
                    <div className='formGroup'>
                      <p className='inputTitle'>PASSWORD</p>
                      <HtTextInput name="password" type="password"></HtTextInput>
                    </div>
                    <div className='check-box-box' style={{marginTop: "-10px"}}>
                      <input type='checkbox' id='keepMeSignedCheckBox'></input>
                      <label for="keepMeSignedCheckBox">Keep me signed in</label>
                    </div>
                    <div className='display-flex bottom-buttons' style={{marginTop: "30px", gap: "15px"}}>
                      <Button type='submit' className='default-button' style={{width: "190px"}}>Sign in</Button>
                      <button className='m-l-auto outlined-button' style={{width: "190px"}}>Register</button>
                    </div>
                    <div className='forgot-your-password' style={{marginTop: "30px"}}>
                      <Link className='forgot-your-password-text' to={'https://alto.glueup.com/'} target='_blank'>Forgot yor password?</Link>
                    </div>
                  </Form>
                </div>
              </Formik>
            </div>
          )}
        </div>
        <ScrollToTop></ScrollToTop>
    </div>
  )
}
