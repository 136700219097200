import ReCAPTCHA from "react-google-recaptcha";

export default function GoogleCaptcha({checkCaptchaIsVerified}) {

  const onChange = (value) => { 
    checkCaptchaIsVerified(true);
  }

  return (
    <>
        <ReCAPTCHA sitekey="6Lf2QykpAAAAAAVmlJDerlrf5viOq1mULExxwGXK" onChange={onChange}/>
    </>
  )
}
