import React from "react";
import "../styles/global.css";
import "../styles/imageSlider.css"
import RcImage from '../attributes/RcImage';

function ImageSlider(images) {

  return (
    <div className="slider">
      <div className="slide-track">
        <div className="image_courosel">
          {Array.isArray(images?.images) && images?.images.map((element, index) => (
            Array.isArray(images?.images) && images?.images.map((image) => (
              <RcImage src={image?.attributes?.url}></RcImage>
            ))
          ))}
        </div>
      </div>
    </div>
  );
}

export default ImageSlider;
