import React from 'react'
import "../../styles/trendsBarometer.css";
import { Link } from 'react-router-dom';
import ScrollToTop from '../../utilities/ScrollToTop';

export default function TrendsBarometer() {

    const bottomTable = [
        {
            id: 1,
            title: "During registration please enter your details for verification.",
            descriptions: [
                "We recommend that the decision maker of each organisation create the user profile with their own e-mail address. This is the only stage when your name or contact e-mail will be requested."
            ]
        },
        {
            id: 2,
            title: "Your details are approved.",
            descriptions: [
                "The Association Manager is the only person who will know that your organisation is included in the data pool, but no one will see your actual data. This step is necessary to only accept genuine organisations to participate in the project."
            ]
        },
        {
            id: 3,
            title: "You will be assigned a randomly generated username and password.",
            descriptions: [
                "Only you will know what your username is, making the platform completely anonymous. The data you enter under your user profile is encrypted and impossible to connect to your personal details. You have to change your password when you first log in"
            ] 
        },
        {
            id: 4,
            title: "Check your spam folder",
            descriptions: [
                "Add altotrends.com as a safe sender on your mail server."
            ] 
        },
        {
            id: 5,
            title: "Enter your weekly arrival figures starting from January 2019",
            descriptions: [
                "Once you have access to the platform you need to enter your weekly arrival figures starting from January 2019. This may take 45-50 minutes, but this is a one off task."
            ]
        },
        {
            id: 6,
            title: "Keep your arrival data updated weekly",
            descriptions: [
                "The weekly time you spend on the update will be only a few seconds",
                "Note: Schools with multiple locations will create 1 user profile. You can select the countries and locations where you offer courses when you finalise your account profile. In order to make data entry as smooth as possible 3 people/ company can log in at the same time, so you can allocate different staff members to keep the data flow up to date.",
                "Important! The data process is real time, collected in a data pool, so once you enter the number of arrivals, you can’t change the figure and need to contact admin to make any amendments. We ask you to pay special attention when entering the figures.",
                "The platform is straightforward and user friendly, we created a user manual to help you get started: ALTO Trends Barometer user manual. Should you need any further assistance, you can contact Reka Lenart Association Manager.",
                "Thank you for your support, please start your registration now."
            ]
        },
      ];

  return (
    <div className='trends-barometer'>
        <div className='trends-barometer-main'>
            <div className='left-section'>
                <div className='title'>
                    <p>ALTO Trends <br/> Barometer  </p>
                </div>
                <div className='sub-title'>
                    <p>Safe-Simple-Vital</p>
                </div>
                <div className='description'>
                    <p>
                        We are excited to launch the ALTO Trends Barometer project which is a data collaboration platform that was developed by the association to assist the international educations industry with insights into measurable trends showing weekly arrivals data at course providers around the world.
                    </p>
                    <p>
                        It has never been more evident that the more data we have access to, the better chance we, as business owners have to survive and even flourish in times of difficulty, while those without data may struggle. The Altotrends.com tool is specifically designed to support the leaders of our industry to maneuver their businesses through the current stormy weather and beyond.
                    </p>
                    <p>
                        We invite all language schools and academic course providers around the world to participate in this project with their weekly adult student arrivals data.
                    </p>
                    <p>
                        The platform is anonymous and safe, using the tool is simple, and the information you gain is vital to your organisation.
                    </p>
                </div>
                <div className='please-complete-button'>
                    <Link><button className='default-button'>Register For the ALTO Trends Barometer</button></Link>
                </div>
            </div>
            <div className='right-section'>
                <div className='image-content'>
                    <img src={require("../../images/trendsBarometer/trends-barometer.png")} alt='industry-pulse'></img>
                </div>
            </div>
        </div>
        <div className='sub-table'>
            {
                bottomTable.map((item, index) => (
                    <div key={item.id} className='content'>
                        <div className='content-inner'>
                            <div className='left-content'>
                                <p>Step {item.id}</p>
                                <p>{item.title}</p>
                            </div>
                            <div className='right-content'>
                                {
                                    item.descriptions.map((description) => (
                                        <p>{description}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
        <ScrollToTop></ScrollToTop>
    </div>
  )
}
