import axios from "axios";
import Constants from "../constants/constants";

export default class HomePageService{

    getStHome(){
        let populate = [
            "Header_Section.Title_Images",
            "Header_Section.Buttons",
            "Header_Section.Title_Images",
            "Blue_Area.Button",
            "Blue_Area.Image",
            "Homepage_About_Us.About_Us_Subheading",
            "Homepage_Products.Image",
            "Homepage_Products.Product_Button"
        ]
        return axios.get(`${Constants.apiServerPath}st-homepage/?populate=${populate}`)
    }
}