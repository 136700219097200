import React, { useEffect, useState } from 'react'
import ScrollToTop from '../../utilities/ScrollToTop';
import "../../styles/blog.css";
import BlogService from '../../services/blogService';
import { Link } from 'react-router-dom';
import RcImage from '../../attributes/RcImage'
import SocialPlatforms from '../../partials/SocialPlatforms/SocialPlatforms';

export default function Blog() {

  const [stBlog, setStBlog] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [blogsForCategory, setBlogsForCategory] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const [blogSearchText, setBlogSearchText] = useState("");
 
  useEffect(() => {
    let blogService = new BlogService();
    blogService.getStBlog().then(result => setStBlog(result.data.data));
  }, [])

  useEffect(() => {
    if(blogSearchText.length <= 0){
      let blogService = new BlogService();
      blogService.getBlogs().then(result => {
        setBlogs(result.data.data);
        setBlogsForCategory(result.data.data);
      });
    }
    else{
      let data = blogsForCategory.forEach((item) => {
        if(item.attributes.Blog_Title.toLowerCase().includes(blogSearchText.trim().toLowerCase())){
          return item;
        }
      });
      setBlogsForCategory(data);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogSearchText])

  useEffect(() => {
    let blogService = new BlogService();
    blogService.getBlogs().then(result => {
      setBlogs(result.data.data);
      setBlogsForCategory(result.data.data);
    });
  }, [])
  

  useEffect(() => {
    let blogCategories = [
       {id: 1, name: "All"},
      { id: 2, name: "Updates"},
      { id: 3, name: "Events"},
      { id: 4, name: "Information"},
      { id: 5, name: "Featured"},
      { id: 6, name: "Uncategorized"},
    ]
    setBlogCategories(blogCategories);
  }, [])

  function getBlogsByCategory(categoryName){
    if(categoryName === 'All'){
      setBlogsForCategory(blogs);
    }
    else{
      let blogsData = blogs.filter(b => b.attributes.Blog_Category === categoryName);
      setBlogsForCategory(blogsData);
    }
  }

  function getCategoryCount(categoryName){
    if(categoryName === 'All'){
      return blogs.length;
    }
    return blogs.filter(b => b.attributes.Blog_Category === categoryName).length;
  }

  return (
    <div className='blog-main'>
      <div className="blog-main-banner joinAltoDiv"
        style={{backgroundImage: `url(${stBlog?.attributes?.Blog_Main_Image?.data?.attributes?.url})`, width: "100%", height: "640px", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundClip: "content-box"}}>
        <div className="joinAltoDivEllipseBlue"></div>
        <div style={{ padding: "0px 800px 0px 80px", gap: "40px", height: "261px"}} className="joinAltoTextComponent">
          <div className="joinAltoTextComponent">
            <h1 style={{ color: "white" }} className="joinAltoHeader1">{stBlog?.attributes?.Main_Title}</h1>
            <h1 style={{ color: "white", textAlign: "left" }} className="joinAltoHeader2">{stBlog?.attributes?.Main_Title_2}</h1>
            {/* <button onClick={() => scroll(750)} className="blueButton" style={{ backgroundColor: "white", color: "#00529C", width: "280px", marginTop: "30px"}}>Explore</button> */}
          </div>

        </div>
      </div>

      <div className='main-content'>
        <div className='right-content'>
          <div className='items'>
            {blogsForCategory.map((blog, index) => (
              <div key={index} className='item'>
                <div className='image-container'>
                  <RcImage src={blog?.attributes?.Blog_Main_Image?.data?.attributes?.url}></RcImage>
                </div>
                <div className='item-content'>
                  <div className='top'>
                      <p>{blog?.attributes?.Blog_Date}</p>
                  </div>
                  <div className='title'>
                    <Link to={`/blog/detail/${blog?.attributes?.url}`}><p>{blog?.attributes?.Blog_Title}</p></Link>
                  </div>
                  <div className='description'>
                    <p>{blog?.attributes?.BlogShortDescription}</p>
                  </div>
                  <div className='read-more'>
                    <Link to={`/blog/detail/${blog?.attributes?.url}`}>Read More</Link>
                  </div>
                </div>
              </div>))}
          </div>
        </div>
        <div className='left-content'>
            <div className='search-container'>
              <RcImage src={require('../../icons/search-black-icon.png')}></RcImage>
              <input onChange={(event) => setBlogSearchText(event.target.value)} type='search' placeholder='Search...'></input>
            </div>
            <div className='social'>
              <p className='blog-left-content-common-title'>Get Social</p>
              <SocialPlatforms></SocialPlatforms>
            </div>
            <div className='categories-container'>
              <p className='blog-left-content-common-title'>Categories</p>
              <div className='categories'>
                {blogCategories.map((category) => (
                  <div key={category.id} onClick={() => getBlogsByCategory(category.name)} className='category'>
                    <span>{category.name} ({getCategoryCount(category.name)})</span>
                    <RcImage src={require('../../icons/right-arrow-blue-icon.png')}></RcImage>
                  </div>
                ))}
              </div>
            </div>
            <div className='recent-posts-container'>
              <p className='blog-left-content-common-title'>Recent Posts</p>
              {blogsForCategory.map((blog, index) => (
                <div className='recent-blogs'>
                  <Link to={`/blog/detail/${blog?.attributes?.url}`}><p className='blog-title'>{blog.attributes.Blog_Title}</p></Link>
                  <p className='date-and-comment'><span className='blog-date'>{blog?.attributes?.Blog_Date}</span></p>
                </div>
              ))}
            </div>
        </div>
      </div>
      
      <ScrollToTop></ScrollToTop>
    </div>
  )
}
