import axios from "axios";
import Constants from "../constants/constants";

export default class AboutUsService{

    getAboutUs(){
        let populate = [
            "Main_Title_About_Us",
            "Main_Title_2_About_Us",
            "About_Us_Subheading.About_Us_Values",
            "Blue_Section.Section_Image",
            "Alto_Board.Board_Members.Picture",
            "Alto_Memories",
            "Testimonials.Testimonial_People.Picture",
            "About_Us_Main_Image"
        ]
        return axios.get(`${Constants.apiServerPath}st-about-us/?populate=${populate}`)
    }

    getTestimonials(){
        return axios.get(`${Constants.apiServerPath}st-about-us/?populate=Testimonials.Testimonial_People.Picture`);
    }
}