import React from "react";
import "../styles/newsCard.css"
import { Link } from "react-router-dom";
import RichText from './RichText'

function NewsCard(props) {

  const attributes = props.file.attributes;

  return (
    <div className="news-card-main">
      <div className="newComponent">
        <div className="newImage">
          <img
            src={attributes?.Main_Image.data?.attributes?.url}
            alt="imageFile"
          ></img>
        </div>
        <div className="newText">
          <Link to={`/projects/detail/${props.file.attributes.url}`}><h1 className="newCardHeader"> {attributes?.Main_Title_1}</h1></Link>
          <div className="textArea">
            <p className="newsCardText"><RichText data={attributes?.Main_Description}></RichText></p>
          </div>
          {attributes?.ShortButton ? (
            <Link to={attributes?.ShortButton?.ButtonLink}><button className="newsButton">{attributes?.ShortButton?.ButtonText}</button></Link>
          ) : false}
        </div>
      </div>
    </div>
  );
}

export default NewsCard;
