import React, { useState, useEffect, useRef } from "react";
import "../../styles/global.css";
import "../../styles/aboutUs.css";
import AltoMemoryCard from "../AltoMemoryCard";
import FrameContainer from "../FrameContainer";
import downArrowIcon from "../../icons/down-arrow-blue-icon.svg";
import upArrowIcon from "../../icons/up-arrow-blue-icon.svg";
import ScrollToTop from "../../utilities/ScrollToTop";
import ContactUs from "../ContactUs";
import { Link, useNavigate } from "react-router-dom";
import AboutUsService from "../../services/aboutUsService";
import RichText from "../RichText";
import EventsService from "../../services/eventsService";
import RcImage from "../../attributes/RcImage";

function AboutUs() {
  const [aboutUs, setAboutUs] = useState([]);
  const [boardMembersReadMoreStates, setBoardMembersReadMoreStates] = useState(
    []
  );
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  const navigateToRoute = (path) => {
    navigate(path);
  };

  useEffect(() => {
    let aboutUsService = new AboutUsService();
    aboutUsService.getAboutUs().then((result) => {
      let data = result.data.data;
      setAboutUs(data);
      if (Array.isArray(data?.attributes?.Alto_Board?.Board_Members)) {
        let count = data?.attributes?.Alto_Board?.Board_Members.length;
        let status = [];
        for (let i = 0; i < count; i++) {
          status.push(false);
        }
        setBoardMembersReadMoreStates(status);
      }
    });
  }, []);

  useEffect(() => {
    let eventsService = new EventsService();
    eventsService.getEvents().then((result) => setEvents(result.data.data));
  }, []);

  function getLastTheereEvent(events) {
    return events.slice(Math.max(events.length - 3, 0)).reverse();
  }

  const scrollToWorldsLeadersRef = useRef(null);
  const scrollToWorldsLeaders = () =>
    scrollToWorldsLeadersRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  function toggleBoardMembersReadMoreStates(index, state) {
    let newStates = [];
    for (let i = 0; i < boardMembersReadMoreStates.length; i++) {
      newStates.push(false);
    }
    newStates[index] = state;
    setBoardMembersReadMoreStates(newStates);
  }

  return (
    <div className="aboutUsPage">
      <div
        className="aboutUsHero joinAltoDiv"
        style={{
          width: "100%",
          height: "640px",
          backgroundSize: "cover",
          backgroundImage: `url(${aboutUs?.attributes?.About_Us_Main_Image?.data?.attributes?.url})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundClip: "content-box",
        }}
      >
        <div className="joinAltoDivEllipseBlue"></div>
        <div
          style={{
            padding: "0px 800px 0px 80px",
            gap: "40px",
            height: "261px",
          }}
          className="joinAltoTextComponent"
        >
          <div className="joinAltoTextComponent">
            <h1 style={{ color: "white" }} className="joinAltoHeader1">
              {aboutUs?.attributes?.Main_Title_About_Us}
            </h1>
            <h1
              style={{ color: "white", textAlign: "left" }}
              className="joinAltoHeader2"
            >
              {aboutUs?.attributes?.Main_Title_2_About_Us}
            </h1>
            <button
              onClick={() => scrollToWorldsLeaders()}
              className="blueButton"
              style={{
                backgroundColor: "white",
                color: "#00529C",
                width: "280px",
                marginTop: "30px",
              }}
            >
              Meet Our Board
            </button>{" "}
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "56px 80px",
          gap: "40px",
        }}
        className="joinAltoDiv2"
      >
        <h1
          className="headerStyle"
          style={{
            position: "relative",
          }}
        >
          {aboutUs?.attributes?.About_Us_Subheading?.Subheading_Title}
        </h1>
        <p className="boxParagraph" style={{ whiteSpace: "pre-line" }}>
          <RichText
            data={aboutUs?.attributes?.About_Us_Subheading?.Description}
          ></RichText>
        </p>
      </div>

      <div
        style={{ margin: "0px auto", width: "100%" }}
        className="joinAltoTextComponent joinAltoTextComponentMissionOrignsMembers"
      >
        {Array.isArray(
          aboutUs?.attributes?.About_Us_Subheading?.About_Us_Values
        ) &&
          aboutUs?.attributes?.About_Us_Subheading?.About_Us_Values?.map(
            (element, index) => (
              <div key={index} className="layOutDiv">
                <div className="title">
                  <h2>{element.Value_Title}</h2>
                </div>
                <div className="description">
                  <p>
                    <RichText data={element.Description}></RichText>
                  </p>
                </div>
              </div>
            )
          )}
      </div>

      <div
        className="bacomeAMember"
        style={{
          width: "100%",
          height: "448px",
          margin: "0px auto",
          background: `linear-gradient(92deg, rgba(0, 82, 156, 0.80) 0.97%, rgba(255, 255, 255, 0.90) 100%), url('${aboutUs?.attributes?.Blue_Section?.Section_Image?.data?.attributes?.url}'), lightgray 50% / cover no-repeat`,
        }}
      >
        <div
          style={{
            marginTop: "104px",
            marginBottom: "104px",
            marginLeft: "40px",
            marginRight: "40px",
            display: "flex",
            flexDirection: "row",
          }}
          className="layOutDiv"
        >
          <div>
            <h1
              style={{ color: "white", textAlign: "left" }}
              className="headerStyle"
            >
              {aboutUs?.attributes?.Blue_Section?.Section_Title}
            </h1>
            <div
              className="descriptionBecomeAMemeber"
              style={{ textAlign: "left", marginTop: "40px" }}
            >
              <p className="homepageaboutUsText">
                <RichText
                  data={aboutUs?.attributes?.Blue_Section?.Section_Description}
                ></RichText>
              </p>
            </div>
          </div>
          <button
            onClick={() => navigateToRoute("/how-to-join-alto")}
            className="blueButton"
            style={{ marginTop: "100px", marginLeft: "auto" }}
          >
            Join Now
          </button>
        </div>
      </div>

      <div className="altoBoard">
        <h1 className="headerStyle">
          {aboutUs?.attributes?.Alto_Board?.Board_Title}
        </h1>
        <p className="boxParagraph">
          <RichText
            data={aboutUs?.attributes?.Alto_Board?.Board_Description}
          ></RichText>
        </p>
        <h1 ref={scrollToWorldsLeadersRef} className="joinAltoHeader2">
          {aboutUs?.attributes?.Alto_Board?.Board_Subheader_Title}
        </h1>
        <div className="ds-card-box">
          {Array.isArray(aboutUs?.attributes?.Alto_Board?.Board_Members) &&
            aboutUs?.attributes?.Alto_Board?.Board_Members?.map(
              (file, index) => (
                <div key={index} className="ds-card">
                  <div className="user-image">
                    <RcImage
                      src={file.Picture?.data?.attributes?.url}
                    ></RcImage>
                  </div>
                  <div className="user-info">
                    <div className="user-name">
                      <p>{file.Name}</p>
                    </div>
                    <div className="user-date">
                      <p>{file.Title_and_Years}</p>
                    </div>
                    {!boardMembersReadMoreStates[index] ? (
                      <div
                        className="user-description"
                        style={{ height: "95px" }}
                      >
                        <p>
                          <RichText data={file.Description}></RichText>
                        </p>
                      </div>
                    ) : (
                      <div className="user-description">
                        <p>
                          <RichText data={file.Description}></RichText>
                        </p>
                      </div>
                    )}
                    <div>
                      {!boardMembersReadMoreStates[index] ? (
                        <div
                          onClick={() =>
                            toggleBoardMembersReadMoreStates(index, true)
                          }
                          className="read-more"
                        >
                          <span>Read more</span>
                          <RcImage src={downArrowIcon}></RcImage>
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            toggleBoardMembersReadMoreStates(index, false)
                          }
                          className="read-more"
                        >
                          <span>Close</span>
                          <RcImage src={upArrowIcon}></RcImage>
                        </div>
                      )}
                    </div>
                    <Link to={file.Linkedin_Accont} target="_blank">
                      <button
                        style={{ width: "100%", marginTop: "20px" }}
                        className="default-button connect-to-linkedin"
                      >
                        Connect on Linkedin
                      </button>
                    </Link>
                  </div>
                </div>
              )
            )}
        </div>
      </div>

      <div className="altoMemories">
        <h1 className="headerStyle">
          {aboutUs?.attributes?.Alto_Memories?.memoryHeader}
        </h1>
        <div style={{ width: "760px" }} className="altoMemoriesDescriptionText">
          <p className="boxParagraph">
            <RichText
              data={aboutUs?.attributes?.Alto_Memories?.memoryText}
            ></RichText>
          </p>
        </div>
        {/*
        <div className="altoFrame">
          {getLastTheereEvent(events).map((altoMemo, index) => (
            <AltoMemoryCard key={index} altoMemo={altoMemo} />
          ))}
        </div>
        */}
        <div className="altoComment">
          <div
            className="altoCommentSubDiv"
            style={{
              width: "760px",
              marginLeft: "340px",
              marginRight: "340px",
            }}
          >
            <h1 className="pageHeaderInlineText">
              {aboutUs?.attributes?.Testimonials?.Description_Title}
            </h1>
          </div>

          <p>
            <RichText
              data={aboutUs?.attributes?.Testimonials?.Description}
            ></RichText>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              padding: "0px",
            }}
          >
            <FrameContainer></FrameContainer>
          </div>
        </div>
      </div>

      <ContactUs
        title={"Have Any Questions?"}
        description={"Contact Us"}
        page={"About Us"}
      ></ContactUs>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}
export default AboutUs;
