import React, { useState, useEffect } from "react";
import "../styles/global.css";
import "../styles/frameContainer.css"
import quato1 from "../images/aboutus/quato1.png";
import quato2 from "../images/aboutus/quato2.png";
import leftArrow from "../icons/left-arrow-blue.svg"
import rightArrow from "../icons/right-arrow-blue.svg"
import AboutUsService from "../services/aboutUsService";
import RichText from './RichText';
import RcImage from '../attributes/RcImage';

function FrameContainer() {

  const [currentFoundingMemeber, setCurrentFoundingMemeber] = useState({});
  const [currentFoundingMemeberIndex, setCurrentFoundingMemeberIndex] = useState(0);
  const [foundingMembers, setFoundingMembers] = useState([]);

  useEffect(() => {
    let aboutUsService = new AboutUsService();
    aboutUsService.getTestimonials().then(result => {
      let data = result.data.data.attributes.Testimonials.Testimonial_People;
      setFoundingMembers(data)
    });
  }, [])

  useEffect(() => {
    setCurrentFoundingMemeber(foundingMembers[currentFoundingMemeberIndex])
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundingMembers])

  function sliderPositionHandler(increment){
    let index;
    if(currentFoundingMemeberIndex === foundingMembers.length - 1 && increment > 0){
      index = 0;
      setCurrentFoundingMemeberIndex(index);
      setCurrentFoundingMemeber(foundingMembers[index]);
    }
    else if(currentFoundingMemeberIndex === 0 && increment < 0){
      index = foundingMembers.length - 1;
      setCurrentFoundingMemeberIndex(index);
      setCurrentFoundingMemeber(foundingMembers[index]);
    }
    else{
      index = currentFoundingMemeberIndex + increment;
      setCurrentFoundingMemeberIndex(index);
      setCurrentFoundingMemeber(foundingMembers[index]);
    }
  }

  return (
    <div className="frameContainer">
      {currentFoundingMemeber ? 
        (
          <div key={currentFoundingMemeber.id}>
            <div className="frameContent">
              <div style={{marginRight: "auto"}}>
                <RcImage src={quato1}></RcImage>
              </div>
              <div style={{marginRight: "auto", marginLeft: "auto"}} className="profilePicture">
                <RcImage className="profilePhoto" src={currentFoundingMemeber?.Picture?.data?.attributes?.url}></RcImage>
              </div>
              <div style={{marginLeft: "auto"}}>
                <RcImage src={quato2}></RcImage>
              </div>
            </div>
            <div className="userInfo">
                <div className="leftArrow cursor-pointer">
                  <RcImage onClick={() => sliderPositionHandler(-1)} src={leftArrow}></RcImage>
                </div>
                <div className="userInfoName" style={{width:"1500px"}}>
                  <div className="userName">
                    <p>{currentFoundingMemeber?.Name}</p>
                  </div>
                  <div className="title">
                    <p>{currentFoundingMemeber?.Company}</p>
                  </div>
                </div>
                <div className="rightArrow cursor-pointer">
                  <RcImage onClick={() => sliderPositionHandler(+1)} src={rightArrow}></RcImage>
                </div>
            </div>
            <div className="user-info-description-dd" style={{width: "960px",display: "flex",justifyContent: "center",alignItems: "center",marginLeft: "250px", marginBottom: "100px"}}>
              <p className="boxParagraph" style={{whiteSpace: "pre-line"}}><RichText data={currentFoundingMemeber?.Testimonial}></RichText></p>
            </div>
          </div>
        ) : false
      }
    </div>
  );
}
export default FrameContainer;
