import axios from "axios";
import AES from "crypto-js/aes";
import Constants from "../constants/constants";

export default class ContactUserService {
  getStContactUs() {
    return axios.get(`${Constants.apiServerPath}st-contact-us/?populate=*`);
  }

  postContactUsForm(formData) {
    let xKey = AES.encrypt(JSON.stringify(formData), Constants.xKey);

    return axios.post(
      `${Constants.apiServerPath}send-mail`,
      { ...formData, page: document.location.href },
      {
        headers: {
          x_key: xKey,
        },
      }
    );
  }
}
