import React from 'react'

export default function RcImage({src, className, onClick}) {

  return (
    <>
        <img src={src} alt="" className={className} onClick={onClick}></img>
    </>
  )
}
