import React, { useEffect, useState } from 'react'
import '../../styles/eventRegister.css'
import EventsService from '../../services/eventsService';
import { useParams } from 'react-router-dom';
import ScrollToTop from '../../utilities/ScrollToTop';
import RcImage from '../../attributes/RcImage';

export default function EventRegister() {

  let { slug } = useParams();

  const [event, setEvent] = useState({})

  useEffect(() => {
      let eventsService = new EventsService();
      eventsService.getEventBySlug(slug).then(result => setEvent(result.data.data[0]))
  }, [slug])

  return (
    <div className='event-register-main'>
      <div className='event-main-image'>
        <RcImage src={event?.attributes?.Event_ID?.Event_Main_Image?.data?.attributes?.url}></RcImage>
        <h1>{event?.attributes?.Event_ID?.Event_Name}</h1>
      </div>
      <div className='register-form-container'>
        <div className='form'>
            <p className='form-title'>Register For The Event</p>
            <p className='form-description'>This event is open to ALTO members only. ALTO member organisations are welcome to delegate 2 senior members of the management team to this event free of charge.</p>
            
            <div className='formGroup'>
                <p className='inputTitle'>Do you hold a valid ALTO Membership?</p>
                <div className='group'>
                    <div className='radio-group'>
                        <input name='membershipGroup' type='radio'></input>
                        <label>Yes</label>
                    </div>
                    <div className='radio-group'>
                        <input name='membershipGroup' type='radio'></input>
                        <label>No</label>
                    </div>
                </div>
            </div>
            <div className='firstNameAndLastName'>
                <div className='formGroup'>
                    <p className='inputTitle'>FIRST NAME</p>
                    <input type='text'></input>
                </div>
                <div className='formGroup'>
                    <p className='inputTitle'>LAST NAME</p>
                    <input type='text'></input>
                </div>
            </div>
            <div className='formGroup'>
                <p className='inputTitle'>ORGANISATION</p>
                <input type='text'></input>
            </div>
            <div className='formGroup'>
                <p className='inputTitle'>EMAIL</p>
                <input type='email'></input>
            </div>
            <div className='formGroup'>
                <p className='inputTitle'>PROFESSIONAL TITLE</p>
                <input type='text'></input>
            </div>
            <div className='formGroup'>
                <p className='inputTitle'>COUNTRY</p>
                <input type='text'></input>
            </div>
            <div className='formGroup'>
                <p className='inputTitle'>SPECIAL DIETS</p>
                <input type='text'></input>
            </div>
            <div className='formGroup'>
                <p className='inputTitle'>YOUR COMMENTS</p>
                <input type='text'></input>
            </div>

            <button className='btnSend'>Send</button>
        </div>
      </div>
      <ScrollToTop></ScrollToTop>
    </div>
  )
}
